export default {
  user: (state) => {
    return state.user
  },

  navigationDrawer: (state) => {
    return state.navigationDrawer
  },

  filteredFlightsList: (state) => {
    return state.filteredFlightsList
  },

  filteredFlightsVisitedList: (state) => {
    return state.filteredFlightsVisitedList
  }
}
