<template>
    <v-row>
        <v-col></v-col>
        <v-col cols="auto" class="pt-6">
        Prikazanih:
        </v-col>
        <v-col cols="auto" class="pt-0">
        <v-select style="width: 60px;" v-model="itemsToShow" :items="itemsToShowList"></v-select>
        </v-col>
        <v-col cols="auto">
        <v-btn class="elevation-0" color="white" @click="currentPage = currentPage - 1" :text="currentPage == 1" :disabled="currentPage == 1" fab small tile>
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <template v-if="leftSidePages.length > 0">
          <v-btn class="elevation-0" v-for="pageButton in leftSidePages" :key="'page-button-' + pageButton" @click="currentPage = pageButton" fab small tile>
              {{ pageButton }}
          </v-btn>
          ...
        </template>
        <v-btn class="elevation-0" :color="(pageButton === currentPage) ? 'primary' : ''" v-for="pageButton in sorroundingPages" :key="'page-button-' + pageButton" @click="currentPage = pageButton" fab small tile>
            {{ pageButton }}
        </v-btn>
        <template v-if="rightSidePages.length > 0">
          ...
          <v-btn class="elevation-0" v-for="pageButton in rightSidePages" :key="'page-button-' + pageButton" @click="currentPage = pageButton" fab small tile>
              {{ pageButton }}
          </v-btn>
        </template>
        <v-btn class="elevation-0 mr-4" color="white" @click="currentPage = currentPage + 1" :text="currentPage == maxPages" :disabled="currentPage == maxPages" fab small tile>
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
  data: () => ({
    itemsToShow: 25,
    currentPage: 1,
    pagesSpan: 2,
    itemsToShowList: [10, 25, 50, 100]
  }),
  watch: {
    numOfData: function (val) {
      this.currentPage = 1
    },
    currentPage: function (val) {
      this.$emit('page-change', val)
    },
    itemsToShow: function (val) {
      this.currentPage = 1
      this.$emit('num-items-change', val)
    }
  },
  props: ['numOfData'],
  methods: {
  },
  computed: {
    maxPages () {
      return Math.ceil(this.numOfData / this.itemsToShow)
    },
    leftSidePages () {
      const pageButtons = []

      if (this.sorroundingPages[0] !== 1) {
        pageButtons.push(1)
      }

      return pageButtons
    },
    rightSidePages () {
      const pageButtons = []

      if (this.sorroundingPages[this.sorroundingPages.length - 1] !== this.maxPages) {
        pageButtons.push(this.maxPages)
      }

      return pageButtons
    },
    sorroundingPages () {
      const pageButtons = []
      const minPage = this.currentPage - this.pagesSpan
      const maxPage = this.currentPage + this.pagesSpan

      if (minPage < 1 && maxPage > this.maxPages) {
        for (let i = 1; i <= this.maxPages; i++) {
          pageButtons.push(i)
        }
      } else if (minPage < 1 && maxPage <= this.maxPages) {
        for (let i = 1; i <= maxPage; i++) {
          pageButtons.push(i)
        }
      } else if (minPage >= 1 && maxPage > this.maxPages) {
        for (let i = minPage; i <= this.maxPages; i++) {
          pageButtons.push(i)
        }
      } else {
        for (let i = minPage; i <= maxPage; i++) {
          pageButtons.push(i)
        }
      }

      return pageButtons
    }
  }
}
</script>
<style scoped>
</style>
