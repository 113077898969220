<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ (detecting) ? 'Prepoznava letov je v teku...' : 'Prepoznam nove lete?' }}</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="detecting">
          <v-row>
            <v-col style="display: flex; align-items: center; justify-content: center;">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <v-row v-else>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pb-0">
            <v-btn color="primary" text @click="dialog = false">
              Zapri
            </v-btn>
            <v-btn class="elevation-0 ml-2" color="primary" @click="detect">
              <v-icon>mdi-plus</v-icon>
              Prepoznaj
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    detecting: false
  }),
  mounted () {
  },
  methods: {
    show () {
      this.dialog = true
      this.detecting = false
    },

    detect () {
      this.detecting = true

      this.$axios.get('flights/detect')
        .then(response => {
          const data = response.data.result

          this.dialog = false
          this.detecting = false
        })
        .catch(error => {
          console.log(error)
          this.dialog = false
          this.detecting = false
        })
    }
  },
  computed: {
  }
}
</script>
<style scoped>
</style>
