<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="display-1 font-weight-medium pt-4">Uporabniki</div>
      </v-col>
      <v-col cols="auto">
        <div>
          <v-text-field label="Išči" v-model="search" append-icon="mdi-magnify"></v-text-field>
        </div>
      </v-col>
      <!-- <v-col cols="auto" class="mt-4">
        <div>
          <v-btn class="elevation-0" color="primary" @click="$refs.addUserDialog.show()">
            <v-icon dark>
              mdi-plus
            </v-icon>
            Dodaj
          </v-btn>
        </div>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <custom-table :headers="tableHeaders" :data="tableData" :search="search">
            <template slot="filters">
              <v-row class="pl-4 pr-4">
                <v-col cols="3" class="pa-0 pl-4 pr-4">
                  <custom-select label="Prikazane vloge" v-model="selectedRoles" :items="roles"></custom-select>
                </v-col>
              </v-row>
            </template>
            <v-chip slot="cell-role" slot-scope="row" color="primary" label v-if="row.text.length > 0">
              {{ row.text }}
            </v-chip>
            <v-chip slot="cell-eavioUser" slot-scope="row" color="green" dark label v-if="row.text.length > 0">
              {{ row.text }}
            </v-chip>
          </custom-table>
        </v-card>
      </v-col>
    </v-row>
    <add-user-dialog ref='addUserDialog'></add-user-dialog>
  </v-container>
</template>

<script>
import CustomTable from '@/components/CustomTable'
import CustomSelect from '@/components/CustomSelect'
import AddUserDialog from './AddUserDialog'

export default {
  data: () => ({
    tableHeaders: [],
    tableData: [],
    roles: ['Administrator', 'Moderator', 'Uporabnik'],
    selectedRoles: [],
    search: ''
  }),
  components: { CustomTable, CustomSelect, AddUserDialog },
  mounted () {
    this.$axios.get('users')
      .then(response => {
        const data = response.data.result

        data.forEach(row => {
          row.eavioUser = (row.id !== '') ? 'EAvio' : ''
          row.fullname = row.lastname + ' ' + row.firstname
        })

        data.sort((a, b) => a.fullname.localeCompare(b.fullname, 'sl'))

        this.tableHeaders = [
          { label: 'ID', style: 'width: 5%; max-width: 5%; text-align: center;' },
          { label: 'Priimek in ime', style: 'width: 20%; max-width: 20%;' },
          { label: 'Uporabniško ime', style: 'width: 20%; max-width: 20%;' },
          { label: 'E-mail', style: 'width: 20%; max-width: 20%;' },
          { label: 'IButton', style: 'width: 15%; max-width: 15%;' },
          { label: 'EAvio', style: 'width: 10%; max-width: 10%;' },
          { label: 'Vloga', style: 'width: 10%; max-width: 10%; text-align: center;' }
        ]

        this.tableData = data.map(row => {
          return [
            { value: row.id, field: 'id', style: 'text-align: center;' },
            { value: row.fullname, field: 'fullname' },
            { value: row.username, field: 'username' },
            { value: row.email, field: 'email' },
            { value: row.ibutton, field: 'ibutton' },
            { value: row.eavioUser, field: 'eavioUser' },
            { value: row.role, field: 'role', style: 'text-align: center;' }
          ]
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style scoped>

</style>
