<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="display-1 font-weight-medium pt-4">IButtoni</div>
      </v-col>
      <v-col cols="auto">
        <div>
          <v-text-field label="Išči" v-model="search" append-icon="mdi-magnify"></v-text-field>
        </div>
      </v-col>
      <!-- <v-col cols="auto" class="mt-4">
        <div>
          <v-btn color="primary">
            <v-icon dark>
              mdi-plus
            </v-icon>
            Dodaj
          </v-btn>
        </div>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <custom-table :headers="tableHeaders" :data="tableData" :search="search"></custom-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomTable from '@/components/CustomTable'

export default {
  data: () => ({
    tableHeaders: [],
    tableData: [],
    search: ''
  }),
  components: { CustomTable },
  mounted () {
    this.$axios.get('ibuttons')
      .then(response => {
        const data = response.data.result

        this.tableHeaders = [
          { label: 'ID', style: 'width: 5%; max-width: 5%; text-align: center;' },
          { label: 'IButton', style: 'width: 30%; max-width: 30%;' },
          { label: 'Ime', style: 'width: 30%; max-width: 30%;' },
          { label: 'Priimek', style: 'width: 30%; max-width: 30%;' }
        ]

        this.tableData = data.map(row => {
          return [
            { value: row.id, field: 'id', style: 'text-align: center;' },
            { value: row.ibutton, field: 'ibutton' },
            { value: row.firstname, field: 'firstname' },
            { value: row.lastname, field: 'lastname' }
          ]
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style scoped>

</style>
