<template>
  <router-view />
</template>

<script>
export default {
  data: () => ({
  })
}
</script>
