<template>
  <div ref="googleMapsContainer"></div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

export default {
  data: () => ({
    gmap: null,
    polylines: [],
    arrowMarkers: {},
    tooltipOverlay: null,
    airportTooltipOverlay: null,
    airportMarkers: {},
    altitudeColorPalette: [
      { min: 0, max: 99, color: '#0000ff' },
      { min: 100, max: 199, color: '#0037ff' },
      { min: 200, max: 299, color: '#006aff' },
      { min: 300, max: 399, color: '#00a2ff' },
      { min: 400, max: 499, color: '#00d9ff' },
      { min: 500, max: 599, color: '#00fff2' },
      { min: 600, max: 699, color: '#00ffbb' },
      { min: 700, max: 799, color: '#00ff88' },
      { min: 800, max: 899, color: '#00ff51' },
      { min: 900, max: 999, color: '#00ff1a' },
      { min: 1000, max: 1099, color: '#1aff00' },
      { min: 1100, max: 1199, color: '#51ff00' },
      { min: 1200, max: 1299, color: '#88ff00' },
      { min: 1300, max: 1399, color: '#bbff00' },
      { min: 1400, max: 1499, color: '#f2ff00' },
      { min: 1500, max: 1599, color: '#ffd900' },
      { min: 1600, max: 1699, color: '#ffa200' },
      { min: 1700, max: 1799, color: '#ff6a00' },
      { min: 1800, max: 1899, color: '#ff3700' },
      { min: 1900, max: null, color: '#ff0000' }
    ]
  }),
  watch: {
    planesPoints: function (newVal, oldVal) {
      this.redraw()
    },
    colorful: function (newVal, oldVal) {
      this.redraw()
    },
    airports: function (newVal, oldVal) {
      this.mapMovedEvent()
    }
  },
  props: ['planesPoints', 'colorful', 'live', 'airports'],
  mounted () {
    const loader = new Loader({
      apiKey: 'AIzaSyDlcg4f0WCAuKc1L4UPaEsG92tWXvr9cDw',
      version: 'weekly',
      libraries: []
    })

    loader
      .load()
      .then(() => {
        class TooltipOverlay extends google.maps.OverlayView {
          constructor () {
            super()
            this.tooltipDiv = document.createElement('div')
            this.tooltipDiv.className = 'gmaps-tooltip'

            this.tooltipLabelsDiv = document.createElement('div')
            this.tooltipLabelsDiv.className = 'gmaps-tooltip-labels'
            this.tooltipLabelsDiv.innerHTML = `
              <div>Naziv:</div>
              <div>Čas:</div>
              <div>Lat / Lon:</div>
              <div>Hitrost:</div>
              <div>Višina / Tla:</div>
            `

            this.tooltipDataDiv = document.createElement('div')
            this.tooltipDataDiv.className = 'gmaps-tooltip-data'

            this.tooltipDiv.appendChild(this.tooltipLabelsDiv)
            this.tooltipDiv.appendChild(this.tooltipDataDiv)
          }

          onAdd () {
            const panes = this.getPanes()
            panes.floatPane.appendChild(this.tooltipDiv)
          }

          show (latLng, tooltipData) {
            const coordinates = this.getProjection().fromLatLngToDivPixel(latLng)

            if (this.tooltipDiv) {
              this.tooltipDataDiv.innerHTML = `
                <div>${tooltipData.plane}</div>
                <div>${tooltipData.time}</div>
                <div>${tooltipData.latitude} / ${tooltipData.longitude}</div>
                <div>${tooltipData.speed}</div>
                <div>${tooltipData.altitude} m / ${tooltipData.ground_altitude} m</div>
              `
              this.tooltipDiv.style = `left: ${coordinates.x}px; top: ${coordinates.y}px; visibility: visible;`
            }
          }

          hide () {
            if (this.tooltipDiv) {
              this.tooltipDiv.style = ''
            }
          }

          onRemove () {
            if (this.tooltipDiv) {
              this.tooltipDiv.parentNode.removeChild(this.tooltipDiv)
              delete this.tooltipLabelsDiv
              delete this.tooltipDataDiv
              delete this.tooltipDiv
            }
          }
        }

        class AirportTooltip extends google.maps.OverlayView {
          constructor () {
            super()
            this.tooltipDiv = document.createElement('div')
            this.tooltipDiv.className = 'airport-tooltip'

            this.tooltipLabelsDiv = document.createElement('div')
            this.tooltipLabelsDiv.className = 'airport-tooltip-labels'
            this.tooltipLabelsDiv.innerHTML = `
              <div>Ident:</div>
              <div>Naziv:</div>
              <div>Država:</div>
              <div>Latitude:</div>
              <div>Longitude:</div>
              <div>Višina:</div>
            `

            this.tooltipDataDiv = document.createElement('div')
            this.tooltipDataDiv.className = 'airport-tooltip-data'

            this.tooltipDiv.appendChild(this.tooltipLabelsDiv)
            this.tooltipDiv.appendChild(this.tooltipDataDiv)
          }

          onAdd () {
            const panes = this.getPanes()
            panes.floatPane.appendChild(this.tooltipDiv)
          }

          show (latLng, tooltipData) {
            const coordinates = this.getProjection().fromLatLngToDivPixel(latLng)

            if (this.tooltipDiv) {
              this.tooltipDataDiv.innerHTML = `
                <div>${tooltipData.ident}</div>
                <div>${tooltipData.name}</div>
                <div>${tooltipData.country}</div>
                <div>${tooltipData.latitude}</div>
                <div>${tooltipData.longitude}</div>
                <div>${tooltipData.elevation}</div>
              `
              this.tooltipDiv.style = `left: ${coordinates.x}px; top: ${coordinates.y}px; visibility: visible;`
            }
          }

          hide () {
            if (this.tooltipDiv) {
              this.tooltipDiv.style = ''
            }
          }

          onRemove () {
            if (this.tooltipDiv) {
              this.tooltipDiv.parentNode.removeChild(this.tooltipDiv)
              delete this.tooltipLabelsDiv
              delete this.tooltipDataDiv
              delete this.tooltipDiv
            }
          }
        }

        this.gmap = new google.maps.Map(this.$refs.googleMapsContainer, {
          center: {
            lat: 46.6554118,
            lng: 16.1571923
          },
          zoom: 12
        })

        this.gmap.addListener('mousemove', (e) => {
          this.mouseMoveEvent(e.latLng)
        })

        this.gmap.addListener('idle', () => {
          this.mapMovedEvent()
        })

        this.tooltipOverlay = new TooltipOverlay()
        this.tooltipOverlay.setMap(this.gmap)

        this.airportTooltipOverlay = new AirportTooltip()
        this.airportTooltipOverlay.setMap(this.gmap)
      })
      .catch(e => {
      })
  },
  methods: {
    redraw (planesPoints) {
      this.polylines.forEach(polyline => {
        polyline.setMap(null)
      })

      this.polylines = []

      for (const key in this.arrowMarkers) {
        this.arrowMarkers[key].setMap(null)
      }

      this.arrowMarkers = {}

      for (const [index, entry] of this.planesPoints.entries()) {
        if (typeof entry.points === 'undefined' || entry.points.length === 0) {
          continue
        }

        if (this.colorful) {
          for (let i = 0; i < entry.points.length - 1; i++) {
            const currentPoint = entry.points[i]
            const nextPoint = entry.points[i + 1]

            const points = [
              { lat: currentPoint.lat, lng: currentPoint.lng },
              { lat: nextPoint.lat, lng: nextPoint.lng }
            ]

            const color = this.altitudeColorPalette.find(altitudeColor => {
              if (altitudeColor.max === null || (altitudeColor.min <= currentPoint.altitude && altitudeColor.max >= currentPoint.altitude)) {
                return true
              }
            }).color

            const flightPath = new google.maps.Polyline({
              path: points,
              geodesic: true,
              strokeColor: color,
              strokeOpacity: 1.0,
              strokeWeight: 2
            })

            flightPath.setMap(this.gmap)

            this.polylines.push(flightPath)
          }
        } else {
          const flightPath = new google.maps.Polyline({
            path: entry.points,
            geodesic: true,
            strokeColor: entry.color,
            strokeOpacity: 1.0,
            strokeWeight: 2
          })

          flightPath.setMap(this.gmap)

          this.polylines.push(flightPath)
        }

        // Nariši puščico

        const lastPoint = entry.points[entry.points.length - 1]

        if (typeof lastPoint !== 'undefined' && lastPoint !== null) { // tu bi lejko dodal -    if (live === true)
          const arrowMarker = new google.maps.Marker({
            position: lastPoint,
            icon: {
              path: 'm 10.544516,1.3e-6 c 0.09528,7e-7 0.151612,0.065515 0.342481,0.5853784 0.02253,0.061361 0.06801,0.3230512 0.06801,0.3230512 -1e-6,0 0.152508,0.00729 0.191887,0.00729 0.07613,2.9e-6 0.179096,0.067882 0.230752,0.1505944 0.160261,0.2566255 0.410493,1.6690013 0.410494,2.3196523 -1e-6,0.4037744 0.03383,0.6457724 0.09716,0.6946815 0.05344,0.041274 0.333661,0.1685177 0.621813,0.2817589 0.508433,0.1998117 0.635896,0.211672 4.313827,0.4080657 2.91258,0.1555268 3.829418,0.2287526 3.966485,0.3157635 0.380049,0.2412578 0.399836,0.7986651 0.06558,1.8532937 l -0.172457,0.5440867 c 0,0 -8.965275,1.484092 -8.965275,1.484092 l -0.752977,5.5113014 0.199174,0.05587 c 0.109482,0.03031 0.844972,0.11119 1.634688,0.179742 0.789716,0.06855 1.480511,0.161157 1.5351,0.206462 0.219453,0.182129 0.047,1.617685 -0.194317,1.617684 -0.380872,1e-6 -1.789083,0.189369 -1.863008,0.250183 -0.05277,0.04343 -1.262983,0.206462 -1.729417,0.206461 -0.466433,1e-6 -1.6766363,-0.163041 -1.7294154,-0.206461 -0.073928,-0.06082 -1.4821369,-0.250182 -1.86301,-0.250183 -0.2413144,0 -0.4137692,-1.435555 -0.1943151,-1.617684 0.054613,-0.04528 0.7453818,-0.137907 1.5350984,-0.206462 0.789716,-0.06855 1.5252074,-0.149431 1.6346888,-0.179743 L 10.126738,14.479008 9.3737615,8.9677066 c 0,0 -8.96527695,-1.484093 -8.96527765,-1.484092 L 0.23602899,6.9395267 C -0.0982252,5.8848976 -0.07843674,5.3274932 0.30161038,5.0862338 0.43867808,4.9992222 1.3555139,4.9259954 4.2680946,4.7704695 7.9460263,4.5740745 8.0734883,4.5622152 8.5819197,4.3624056 8.8700721,4.2491632 9.1502949,4.1219185 9.203734,4.0806463 9.2670561,4.0317365 9.3008895,3.7897388 9.3008915,3.3859645 9.3008904,2.7353134 9.5511207,1.3229348 9.7113852,1.0663118 9.7630372,0.9835994 9.865999,0.9157143 9.9421353,0.9157164 c 0.039383,-2.8e-6 0.1894597,-0.00729 0.1894597,-0.00729 0,0 0.04548,-0.2616912 0.06801,-0.3230505 C 10.390475,0.0655152 10.449238,0 10.544517,0 Z',
              scale: 1.2,
              rotation: lastPoint.angle,
              fillColor: entry.color,
              fillOpacity: 1,
              strokeWeight: 0,
              anchor: new google.maps.Point(11, 10)
            }
          })

          arrowMarker.setMap(this.gmap)

          this.arrowMarkers.[lastPoint.imei] = arrowMarker
        }
      }
    },
    mapMovedEvent () {
      if (this.gmap.getZoom() >= 8 && this.airports === true) {
        const bounds = this.gmap.getBounds()
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()

        this.$axios.get(`airports/${sw.lat()}/${sw.lng()}/${ne.lat()}/${ne.lng()}`)
          .then(response => {
            const airports = response.data.result.map(airport => {
              return {
                id: airport[0],
                ident: airport[1],
                name: airport[2],
                country: airport[3],
                latitude: airport[4],
                longitude: airport[5],
                elevation: airport[6]
              }
            })

            for (const key in this.airportMarkers) {
              const found = airports.find(airport => airport.id === parseInt(key))

              if (typeof found === 'undefined' || found === null) {
                this.airportMarkers[key].setMap(null)
                this.airportMarkers[key] = null
                delete this.airportMarkers[key]
              }
            }

            airports.forEach(airport => {
              const latLng = new google.maps.LatLng({ lat: airport.latitude, lng: airport.longitude })
              const airportMarker = new google.maps.Marker({
                position: latLng,
                icon: {
                  url: '/Airport.svg',
                  anchor: new google.maps.Point(11, 10),
                  scaledSize: new google.maps.Size(20, 20)
                }
              })

              airportMarker.setZIndex(-10)

              if (typeof this.airportMarkers[airport.id] === 'undefined' || this.airportMarkers[airport.id] === null) {
                const tooltipData = {
                  ident: airport.ident,
                  name: airport.name,
                  country: airport.country,
                  latitude: airport.latitude,
                  longitude: airport.longitude,
                  elevation: (airport.elevation !== null) ? airport.elevation + ' m' : ''
                }

                airportMarker.addListener('mousedown', (event) => {
                  const latLng = event.latLng
                  this.airportTooltipOverlay.show(latLng, tooltipData)
                })

                airportMarker.addListener('mouseup', (event) => {
                  this.airportTooltipOverlay.hide()
                })

                airportMarker.addListener('mouseover', (event) => {
                  const latLng = event.latLng
                  this.airportTooltipOverlay.show(latLng, tooltipData)
                })

                airportMarker.addListener('mouseout', (event) => {
                  this.airportTooltipOverlay.hide()
                })

                airportMarker.setMap(this.gmap)
                this.airportMarkers[airport.id] = airportMarker
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        for (const key in this.airportMarkers) {
          this.airportMarkers[key].setMap(null)
        }

        this.airportMarkers = {}
      }
    },
    mouseMoveEvent (latLng) {
      this.clearMarkerAndTooltip()

      let closestPoint = null
      let distance = null

      for (const [index, entry] of this.planesPoints.entries()) {
        entry.points.forEach(point => {
          const x1 = latLng.lat()
          const y1 = latLng.lng()
          const x2 = point.lat
          const y2 = point.lng

          const newDistance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))

          if (distance === null || distance > newDistance) {
            distance = newDistance
            closestPoint = point
          }
        })
      }

      this.$emit('hover', (distance < 0.005) ? closestPoint : null)
    },
    moveTo (point) {
      const center = new google.maps.LatLng(point.latitude, point.longitude)
      this.gmap.panTo(center)
    },
    clearMarkerAndTooltip () {
      for (const [index, entry] of this.planesPoints.entries()) {
        const lastPoint = entry.points[entry.points.length - 1]
        this.moveMarker(lastPoint)
      }

      this.tooltipOverlay.hide()
    },
    showMarkerAndTooltip (point) {
      const date = point.timestamp
      let hours = date.getHours()
      hours = (hours < 10) ? '0' + hours : hours
      let minutes = date.getMinutes()
      minutes = (minutes < 10) ? '0' + minutes : minutes
      let seconds = date.getSeconds()
      seconds = (seconds < 10) ? '0' + seconds : seconds
      const timeString = hours + ':' + minutes + ':' + seconds

      const tooltipData = {
        plane: point.plane,
        imei: point.imei,
        time: timeString,
        latitude: point.latitude,
        longitude: point.longitude,
        altitude: point.altitude,
        speed: point.speed,
        angle: point.angle,
        ground_altitude: (point.ground_altitude !== null) ? point.ground_altitude : ''
      }

      const latLng = new google.maps.LatLng({ lat: point.latitude, lng: point.longitude })

      this.moveMarker(point)
      this.tooltipOverlay.show(latLng, tooltipData)
    },
    moveMarker (point) {
      const latLng = new google.maps.LatLng({ lat: point.latitude, lng: point.longitude })

      this.arrowMarkers[point.imei].setPosition(latLng)

      const icon = this.arrowMarkers[point.imei].getIcon()
      icon.rotation = point.angle

      this.arrowMarkers[point.imei].setIcon(icon)
    },
    setMapPositionToBounds (minLat, minLng, maxLat, maxLng) {
      const latLng1 = new google.maps.LatLng({ lat: minLat, lng: minLng })
      const latLng2 = new google.maps.LatLng({ lat: maxLat, lng: maxLng })

      const latLngBounds = new google.maps.LatLngBounds(latLng1, latLng2)

      this.gmap.fitBounds(latLngBounds)
    }
  }
}
</script>

<style>
.gm-fullscreen-control, .gm-svpc {
  display: none;
}

.gmaps-marker {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-5px, -5px);
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FF0000;
  visibility: hidden;
}

.gmaps-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(20px);
  padding: 10px;
  font-size: 10pt;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  z-index: 100;
  visibility: hidden;
}

.gmaps-tooltip > div {
  float: left;
}

.gmaps-tooltip-labels {
  font-weight: bold;
}

.gmaps-tooltip-labels > div {
  text-align: right;
}

.gmaps-tooltip .gmaps-tooltip-data {
  margin-left: 10px;
}

.airport-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(40px, -10px);
  padding: 10px;
  font-size: 10pt;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  z-index: 100 !important;
  visibility: hidden;
}

.airport-tooltip > div {
  float: left;
}

.airport-tooltip-labels {
  font-weight: bold;
}

.airport-tooltip-labels > div {
  text-align: right;
}

.airport-tooltip .airport-tooltip-data {
  margin-left: 10px;
}
</style>
