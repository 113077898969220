<template>
  <v-select :value="value" :items="items" :item-value="itemValue" :item-text="itemText" attach :label="label" @change="val => $emit('input', val)" multiple :outlined="outlined" :dense="dense" :prepend-icon="prependIcon">
    <template v-slot:selection="{ index }">
      <span v-if="index === 0">Izbranih: {{ value.length }}</span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggle">
        <v-list-item-action>
          <v-icon color="primary">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ selectText }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  data: () => ({
    localSelected: []
  }),
  props: ['value', 'items', 'itemValue', 'itemText', 'label', 'outlined', 'dense', 'prependIcon'],
  methods: {
    toggle () {
      if (this.value.length > 0) {
        this.$emit('input', [])
      } else {
        this.$emit('input', this.items.map(item => item[this.itemValue]))
      }
    }
  },
  computed: {
    icon () {
      if (this.value.length === this.items.length) {
        return 'mdi-close-box'
      }

      if (this.value.length > 0) {
        return 'mdi-minus-box'
      }

      return 'mdi-checkbox-blank-outline'
    },

    selectText () {
      if (this.value.length > 0) {
        return 'Počisti izbiro'
      }

      return 'Izberi vse'
    },

    firstSelectedItem () {
      const result = this.items.find(item => item[this.itemValue] === this.localSelected[0])
      console.log(result)
      return result[this.itemValue]
    }
  }
}
</script>
