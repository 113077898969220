<template>
  <v-dialog v-model="dialog" max-width="1024px">
    <v-card>
      <v-card-title>
        <span class="headline">Podrobnosti leta</span>
      </v-card-title>
      <v-tabs v-model="tab" v-if="flight && flight.skydivers">
        <v-tab :key="'view'">Podrobnosti</v-tab>
        <v-tab :key="'skydivers'">Padalci</v-tab>
      </v-tabs>
      <v-card-text>
        <v-container v-if="flight">
          <v-tabs-items v-model="tab" v-if="flight.skydivers">
            <v-tab-item :key="'view'">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Pilot" :value="flight.pilot" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Letalo" :value="flight.plane" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Čas vzleta" :value="formatDateTime(new Date(flight.start_timestamp))" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Čas pristanka" :value="formatDateTime(new Date(flight.end_timestamp))" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Kraj vzleta" :value="flight.takeoff_airport['name']" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Kraj pristanka" :value="flight.landing_airport['name']" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Namen" :value="flight.purpose" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Opombe" :value="flight.comment" readonly></v-text-field>
                </v-col>
                <v-col cols="12" v-if="flight.warnings && flight.warnings.length > 0">
                  <v-text-field label="Opozorila" :value="flight.warnings" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pb-0">
                  <v-btn color="primary" text @click="dialog = false">
                    Zapri
                  </v-btn>
                  <v-btn class="elevation-0 ml-2" color="primary" @click="showFlight" :disabled="!viewFlightButton">
                    Prikaži let
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :key="'skydivers'">
              <v-list>
                <v-list-item v-for="skydiver in addedSkydivers" :key="skydiver.id">
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="6" md="3">
                        <v-select v-model="skydiver.id" label="Padalec" :items="availableSkydivers(skydiver.id)" item-value="id" item-text="name" :readonly="flight.status === 'Potrjen'"></v-select>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-select v-model="skydiver.purposeId" label="Namen skoka" :items="purposes" item-value="id" item-text="label" :readonly="flight.status === 'Potrjen'"></v-select>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-text-field label="Višina skoka" v-model="skydiver.height" suffix="m" :readonly="flight.status === 'Potrjen'"></v-text-field>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-text-field label="Komentar pilota" v-model="skydiver.pilotComment" :readonly="flight.status === 'Potrjen'"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="grey" @click="removeSkydiver(skydiver.id)" fab x-small dark depressed :disabled="flight.status === 'Potrjen'">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="addedSkydivers && addedSkydivers.length > 0"></v-divider>
                <v-list-item>
                  <v-list-item-title></v-list-item-title>
                  <v-list-item-action>
                    <v-btn color="primary" @click="addSkydiver" fab x-small dark depressed :disabled="addedSkydivers.length === skydivers.length || flight.status === 'Potrjen'">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pb-0">
                  <v-btn color="primary" text @click="dialog = false">
                    Zapri
                  </v-btn>
                  <v-btn class="elevation-0 ml-2" color="primary" @click="updateSkydivers" :loading="saving" v-if="flight.skydivers" :disabled="flight.status === 'Potrjen'">
                    Shrani
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <v-row v-else>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Pilot" :value="flight.pilot" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Letalo" :value="flight.plane" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Čas vzleta" :value="formatDateTime(new Date(flight.start_timestamp))" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Čas pristanka" :value="formatDateTime(new Date(flight.end_timestamp))" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Kraj vzleta" :value="flight.takeoff_airport['name']" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Kraj pristanka" :value="flight.landing_airport['name']" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Namen" :value="flight.purpose" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Opombe" :value="flight.comment" readonly></v-text-field>
            </v-col>
            <v-col cols="12" v-if="flight.warnings && flight.warnings.length > 0">
              <v-text-field label="Opozorila" :value="flight.warnings" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!flight.skydivers">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pb-0">
              <v-btn color="primary" text @click="dialog = false">
                Zapri
              </v-btn>
              <v-btn class="elevation-0 ml-2" color="primary" @click="showFlight" :disabled="!viewFlightButton">
                Prikaži let
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    flight: null,
    skydivers: [],
    purposes: [],
    addedSkydivers: [],
    saving: false,
    tab: 'view',
    tabs: ['view', 'skydivers'],
    viewFlightButton: false,
    initialJumpHeight: null,
    isEavio: false,
    axiosOptions: null
  }),
  watch: {
    dialog (val) {
      if (!val) {
        this.flight = null
        this.viewFlightButton = false
        this.tab = 'view'
      }
    }
  },
  mounted () {
    if (this.$route.name === 'EavioFlights') {
      this.isEavio = true

      this.axiosOptions = {
        headers: {
          eavio: this.$route.params.hash
        }
      }
    }
  },
  methods: {
    show (flight) {
      this.tab = 'view'
      this.flight = { ...flight }
      this.initialJumpHeight = null

      if (flight.skydivers) {
        this.addedSkydivers = flight.skydivers.map(skydiver => {
          return {
            id: skydiver.id,
            purposeId: skydiver.purpose_id,
            height: skydiver.height,
            pilotComment: skydiver.pilot_comment
          }
        })
      }

      if (flight.skydivers) {
        if (this.addedSkydivers.length > 0) {
          this.initialJumpHeight = this.addedSkydivers[0].height
        } else {
          const re = new RegExp('Višina skoka: ([0-9]*)m')
          const result = re.exec(flight.comment)

          console.log(result)

          if (result) {
            this.initialJumpHeight = parseInt(result[1])
          }
        }
      }

      this.dialog = true

      this.$axios.get(`flight/data/id/${flight.eavio_flight_id}`, this.axiosOptions)
        .then(response => {
          const data = response.data.result

          if ('points' in data) {
            this.viewFlightButton = true
          }
        })
        .catch(error => {
          console.log(error)
        })

      if (flight.skydivers) {
        this.$axios.get('skydivers')
          .then(response => {
            const data = response.data.result

            this.skydivers = data.map(skydiver => {
              return {
                id: skydiver.id,
                name: skydiver.lastname + ' ' + skydiver.firstname,
                height: skydiver.height
              }
            })
          })
          .catch(error => {
            console.log(error)
          })

        this.$axios.get('purposes')
          .then(response => {
            const data = response.data.result

            this.purposes = data.map(purpose => {
              return {
                id: purpose.id,
                label: purpose.label,
                sectionId: purpose.section_id
              }
            })

            this.purposes = this.purposes.filter(purpose => purpose.sectionId === 21) // Section ID 21 je sekcija za skoke
          })
          .catch(error => {
            console.log(error)
          })
      }
    },

    addSkydiver () {
      if (this.addedSkydivers.length < this.skydivers.length) {
        let height = 1500

        if (this.initialJumpHeight) {
          height = this.initialJumpHeight
        }

        this.addedSkydivers.push({
          id: null,
          purposeId: 27, // 27 je Skok
          height,
          pilotComment: ''
        })
      }
    },

    removeSkydiver (id) {
      const index = this.addedSkydivers.findIndex(skydiver => skydiver.id === id)
      this.addedSkydivers.splice(index, 1)
    },

    updateSkydivers () {
      this.saving = true

      this.$axios.put(`flight/${this.flight.eavio_flight_id}/`, {
        skydivers: this.addedSkydivers.map(skydiver => {
          return {
            id: skydiver.id,
            purpose_id: skydiver.purposeId,
            height: skydiver.height,
            pilot_comment: skydiver.pilotComment
          }
        })
      })
        .then(response => {
          const result = response.data.result

          this.saving = false
        })
        .catch(error => {
          this.saving = false
          console.log(error)
        })
    },

    availableSkydivers (id) {
      if (this.flight && this.flight.skydivers) {
        const availableSkydivers = this.skydivers.filter(skydiver => !this.addedSkydivers.find(addedSkydiver => addedSkydiver.id === skydiver.id))
        if (id) {
          availableSkydivers.push(this.skydivers.find(skydiver => skydiver.id === id))
        }

        availableSkydivers.sort((a, b) => a.name.localeCompare(b.name, 'sl'))

        return availableSkydivers
      } else {
        return []
      }
    },

    formatDateTime (dateTime) {
      const day = ('0' + dateTime.getDate()).slice(-2)
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2)
      const year = dateTime.getFullYear()
      const minutes = ('0' + dateTime.getMinutes()).slice(-2)
      const hours = ('0' + dateTime.getHours()).slice(-2)

      return `${day}.${month}.${year}, ${hours}:${minutes}`
    },

    showFlight () {
      this.$router.push('/flights/' + this.flight.eavio_flight_id)
    },

    redirect () {
      window.location.href = 'https://akms.eavio.club/control/resources/chronometries/' + this.flight.eavio_flight_id
    }
  },
  computed: {
  }
}
</script>
<style scoped>
</style>
