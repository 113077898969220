<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="display-1 font-weight-medium pt-4">Nastavitve</div>
      </v-col>
      <v-col cols="auto">
        <div>
          <v-text-field label="Išči" append-icon="mdi-magnify"></v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
  components: {},
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style scoped>
</style>
