<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
      :value="dateTimeFormatted"
      :label="label"
      prepend-icon="mdi-calendar"
      readonly
      v-bind="attrs"
      v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" :min="min" :max="max" :allowed-dates="allowedDates" @change="submit" :first-day-of-week="1" locale="sl-SI"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menu: false,
    date: ''
  }),
  watch: {
    value: function (newVal, oldVal) {
      this.setDate(newVal)
    }
  },
  props: ['label', 'value', 'min', 'max', 'allowed-dates'],
  mounted () {
    if (this.value !== null) {
      this.setDate(this.value)
    }
  },
  methods: {
    setDate (date) {
      if (date === null) {
        this.date = ''
      } else {
        let day = '' + date.getDate()
        let month = '' + (date.getMonth() + 1)
        const year = date.getFullYear()

        if (day.length < 2) {
          day = '0' + day
        }

        if (month.length < 2) {
          month = '0' + month
        }

        this.date = `${year}-${month}-${day}`
      }
    },
    getDateFormatted (date) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [day, month, year].join('.')
    },
    submit () {
      const date = new Date(this.date + 'T00:00:00')
      this.$emit('input', date)
      this.menu = false
    }
  },
  computed: {
    dateTimeFormatted () {
      if (this.date !== '') {
        return this.getDateFormatted(this.date)
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
</style>
