<template>
  <v-app id="inspire" style="background-color: #EEEEEE">
    <v-container class="fill-height">
      <v-main>
        <div class="login-alert-container">
          <v-alert text type="error" icon="mdi-alert" v-if="error">
            Napačno uporabniško ime ali geslo!
          </v-alert>
        </div>
        <v-card class="login-container" flat>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="username" label="Uporabniško ime"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="password" label="Geslo" type="password"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-4">
                    <v-btn color="primary" elevation="0" @click="submit">Prijava</v-btn>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-checkbox v-model="remember" label="Zapomni si me"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    username: '',
    password: '',
    remember: false,
    error: false
  }),
  mounted () {
    window.addEventListener('keyup', (e) => {
      if (e.keyCode === 13) {
        this.submit()
      }
    })
  },
  methods: {
    submit () {
      this.$axios.post('auth', { username: this.username, password: this.password })
        .then(response => {
          const jwtToken = response.data.result

          localStorage.setItem('jwtToken', jwtToken)

          this.$router.push('/')
        })
        .catch(error => {
          this.error = true

          setTimeout(() => {
            this.error = false
          }, 3000)

          console.log(error)
        })
    }
  }
}
</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #EEEEEE;
}

.login-alert-container {
  position: absolute;
  left: 50%;
  transform: translateX(-200px);
  margin-top: -80px;
  width: 400px;
}

.login-container {
  width: 400px;
  margin: 0 auto;
}
</style>
