<!-- CSS za dygraph-legend je nastavljen v App.vue -->
<template>
  <div ref="dygraph" style="width: 100%; max-width: 100%; min-width: 100%;"></div>
</template>

<script>
import Dygraph from 'dygraphs'
import DygraphCrosshairPlugin from '../../node_modules/dygraphs/dist/dygraph-crosshair.js'
import '../../node_modules/dygraphs/dist/dygraph.min.css'

export default {
  data: () => ({
    dygraph: null
  }),
  watch: {
    data (newVal) {
      if (this.dygraph !== null) {
        this.dygraph.updateOptions({ file: newVal })
      }
    }
  },
  props: ['data', 'title', 'imei'],
  mounted () {
    this.init()
  },
  methods: {
    init () {
      Dygraph.Plugins.Crosshair = DygraphCrosshairPlugin()

      this.dygraph = new Dygraph(this.$refs.dygraph,
        this.data,
        {
          labels: ['x', 'Višina', 'Hitrost', 'Zemlja'],
          legend: 'always',
          height: 200,
          title: this.title,
          digitsAfterDecimal: 0,
          series: {
            Visina: {
              axis: 'y1'
            },
            Hitrost: {
              axis: 'y2'
            },
            Zemlja: {
              fillGraph: true,
              color: 'brown'
            }
          },
          axes: {
            y: {
              axisLabelWidth: 30
            },
            y2: {
              axisLabelWidth: 30
            }
          },
          // xlabel: 'Čas',
          // ylabel: 'Višina',
          // y2label: 'Hitrost',
          legendFormatter: (data) => {
            // if (data.x === null) {
            //  return
            // }

            let timeString = ''
            let altitude = ''
            let speed = ''
            let ground = ''

            // Kaže zadnjo vrednost, če miška ni na grafu
            if (typeof data.series[0].y === 'undefined') {
              const lastPoint = this.data[this.data.length - 1]

              const date = new Date(lastPoint[0])
              let hours = date.getHours()
              hours = (hours < 10) ? '0' + hours : hours
              let minutes = date.getMinutes()
              minutes = (minutes < 10) ? '0' + minutes : minutes
              let seconds = date.getSeconds()
              seconds = (seconds < 10) ? '0' + seconds : seconds

              timeString = hours + ':' + minutes + ':' + seconds
              altitude = '<span style="color: ' + data.series[0].color + '; font-weight: bold;">' + data.series[0].label + ': </span>' + lastPoint[1]
              speed = '<span style="color: ' + data.series[1].color + '; font-weight: bold;">' + data.series[1].label + ': </span>' + lastPoint[2]
              ground = '<span style="color: ' + data.series[2].color + '; font-weight: bold;">' + data.series[2].label + ': </span>' + lastPoint[3]
            } else {
              const date = new Date(data.x)
              let hours = date.getHours()
              hours = (hours < 10) ? '0' + hours : hours
              let minutes = date.getMinutes()
              minutes = (minutes < 10) ? '0' + minutes : minutes
              let seconds = date.getSeconds()
              seconds = (seconds < 10) ? '0' + seconds : seconds

              timeString = hours + ':' + minutes + ':' + seconds
              altitude = '<span style="color: ' + data.series[0].color + '; font-weight: bold;">' + data.series[0].label + ': </span>' + data.series[0].y
              speed = '<span style="color: ' + data.series[1].color + '; font-weight: bold;">' + data.series[1].label + ': </span>' + data.series[1].y
              ground = '<span style="color: ' + data.series[2].color + '; font-weight: bold;">' + data.series[2].label + ': </span>' + data.series[2].y
            }

            return timeString + ' - ' + altitude + ' ' + speed + ' ' + ground
          },
          plugins: [
            new Dygraph.Plugins.Crosshair({
              direction: 'vertical'
            })
          ],
          clickCallback: (e, x, pts) => {
            this.$emit('click', { imei: this.imei, timestamp: new Date(x) })
          },
          highlightCallback: (e, x, pts, row) => {
            this.$emit('hover', { imei: this.imei, timestamp: new Date(x) })
          },
          unhighlightCallback: (e) => {
            this.$emit('hover', null)
          },
          interactionModel: {
            dblclick: function (event, g, context) { Dygraph.defaultInteractionModel.dblclick(event, g, context) },
            mousedown: function (event, g, context) { Dygraph.defaultInteractionModel.mousedown(event, g, context) },
            touchend: function (event, g, context) { },
            touchmove: function (event, g, context) { },
            touchstart: function (event, g, context) {
              /* switch (event.touches.length) {
                case 1:
                  Dygraph.defaultInteractionModel.touchstart(event, g, context)
                  break
                case 2:
                  Dygraph.defaultInteractionModel.dblclick(event, g, context)
                  break
                default:
                  console.log('Not supported')
              } */
            },
            willDestroyContextMyself: true
          }
        })
    },
    clear () {
      this.dygraph.clearSelection()
      this.dygraph.updateOptions({ title: this.title })
    },
    select (point) {
      const row = this.dygraph.getRowForX(point.timestamp)
      this.dygraph.setSelection(row)
    },
    updateTitle (point) {
      this.dygraph.updateOptions({ title: point.plane + ' | ' + point.pilot })
    },
    resize () {
      this.dygraph = null
      this.$refs.dygraph.innerHTML = ''

      this.$nextTick(() => {
        this.init()
      })
    }
  }
}
</script>

<style scoped>
</style>
