export default {
  setUser: (state, payload) => {
    state.user = payload
  },
  toggleNavigationDrawer: (state) => {
    state.navigationDrawer = !state.navigationDrawer
  },
  setNavigationDrawer: (state, payload) => {
    state.navigationDrawer = payload
  },
  setFilteredFlightsList: (state, payload) => {
    state.filteredFlightsList = payload
  },
  addToFilteredFlightsVisitedList: (state, payload) => {
    if (!state.filteredFlightsVisitedList.includes(payload)) {
      state.filteredFlightsVisitedList.push(payload)
    }
  },
  resetFilteredFlightsVisitedList: (state) => {
    state.filteredFlightsVisitedList = []
  }
}
