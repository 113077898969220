<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="display-1 font-weight-medium pt-4">Letala</div>
      </v-col>
      <v-col cols="auto">
        <div>
          <v-text-field label="Išči" v-model="search" append-icon="mdi-magnify"></v-text-field>
        </div>
      </v-col>
      <!-- <v-col cols="auto" class="mt-4">
        <div>
          <v-btn color="primary" @click="$refs.addPlaneDialog.show()">
            <v-icon dark>
              mdi-plus
            </v-icon>
            Dodaj
          </v-btn>
        </div>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <custom-table :headers="tableHeaders" :data="tableData" :search="search">
            <template slot="filters">
              <v-row class="pl-4 pr-4">
                <v-col cols="3" class="pa-0 pl-4 pr-4">
                  <custom-select label="Dodatna polja" v-model="selectedTableFields" :items="tableFields" multiple></custom-select>
                </v-col>
              </v-row>
            </template>
            <v-chip slot="cell-color" slot-scope="row" :color="row.text" dark label>
              {{ row.text }}
            </v-chip>
          </custom-table>
        </v-card>
      </v-col>
    </v-row>
    <add-plane-dialog ref="addPlaneDialog"></add-plane-dialog>
  </v-container>
</template>

<script>
import CustomTable from '@/components/CustomTable'
import CustomSelect from '@/components/CustomSelect'
import AddPlaneDialog from './AddPlaneDialog'

export default {
  data: () => ({
    tableHeaders: [],
    tableData: [],
    tableFields: [
      'Block time', 'Flight time', 'Met padalcev', 'Aerovleka', 'Motorno letalo', 'Jadralno letalo', 'Napetost', 'Min. hitrost', 'Max. hitrost', 'Max. G'
    ],
    selectedTableFields: [],
    search: ''
  }),
  components: { CustomTable, CustomSelect, AddPlaneDialog },
  mounted () {
    this.$axios.get('planes')
      .then(response => {
        const data = response.data.result

        this.tableHeaders = [
          { label: 'ID', style: 'width: 5%; max-width: 5%; text-align: center;' },
          { label: 'Tip letala', style: 'width: 20%; max-width: 20%;' },
          { label: 'Registracija', style: 'width: 20%; max-width: 20%;' },
          { label: 'IMEI', style: 'width: 40%; max-width: 40%;' },
          { label: 'Barva', style: 'text-align: center;' }
        ]

        this.tableData = data.map(row => {
          return [
            { value: row.id, field: 'id', style: 'text-align: center;' },
            { value: row.name, field: 'name' },
            { value: row.registration, field: 'registration' },
            { value: row.imei, field: 'imei' },
            { value: row.color, field: 'color', style: 'text-align: center;' }
          ]
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style scoped>

</style>
