import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Empty from '@/views/Empty.vue'
import Login from '@/views/Login.vue'
import LiveMap from '@/views/LiveMap.vue'
import Flights from '@/views/flights/Flights.vue'
import Flight from '@/views/flights/Flight.vue'
import Planes from '@/views/planes/Planes.vue'
import Imeis from '@/views/imeis/Imeis.vue'
import Users from '@/views/users/Users.vue'
import IButtons from '@/views/ibuttons/IButtons.vue'
import Settings from '@/views/settings/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        name: 'map',
        path: 'map',
        component: LiveMap
      },
      {
        name: 'Flights',
        path: 'flights',
        component: Flights
      },
      {
        name: 'flightid',
        path: 'flights/:id',
        component: Flight
      },
      {
        path: 'planes',
        component: Planes
      },
      {
        path: 'imeis',
        component: Imeis
      },
      {
        path: 'users',
        component: Users
      },
      {
        path: 'ibuttons',
        component: IButtons
      },
      {
        path: 'settings',
        component: Settings
      },
      {
        name: 'EavioMap',
        path: '/eavio/:hash/map',
        component: LiveMap
      },
      {
        name: 'EavioFlights',
        path: '/eavio/:hash/flights',
        component: Flights
      }
    ]
  },
  {
    path: '/flight/',
    component: Empty,
    children: [
      {
        name: 'Flight',
        path: ':hash',
        component: Flight
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('jwtToken') || navigator.userAgent === 'AKMS'

  if (to.name !== 'Login' && to.name !== 'Flight' && to.name !== 'EavioMap' && to.name !== 'EavioFlights' && !isAuthenticated) next({ name: 'Login' })

  next()
})

export default router
