<template>
  <div>
    <slot name="filters"></slot>
    <div style="overflow-x: scroll;">
      <table class="custom-table" cellspacing="0" cellpadding="0">
        <thead>
          <th :style="header.style" v-for="header in headers" :key="header.label">
            {{ header.label }}
          </th>
        </thead>
        <tbody>
          <tr :style="(typeof activeRows !== 'undefined' && typeof row !== 'undefined' && activeRows.includes(`${row[0].value}`)) ? 'background-color: #EEE;' : ''" v-for="(row, i) in pagedData" :key="'tr-' + i" @click="rowClicked(row)">
            <td :style="cell.style" v-for="(cell, j) in row" :key="'cell-' + j">
              <slot :name="'cell-' + cell.field" :text="cell.label">
                <span v-html="filteredLabel(cell.label)"></span>
              </slot>
            </td>
          </tr>
          <slot name="customRows"></slot>
        </tbody>
      </table>
    </div>
    <custom-table-pagination class="mt-4" :num-of-data="(dataLength) ? dataLength : filteredData.length" @page-change="page => pageChanged(page)" @num-items-change="num => numItemsChanged(num)" v-if="dataLength || filteredData.length > 0"></custom-table-pagination>
  </div>
</template>

<script>
import CustomTablePagination from '@/components/CustomTablePagination.vue'

export default {
  data: () => ({
    currentPage: 1,
    itemsToShow: 25
  }),
  props: ['headers', 'data', 'search', 'dataLength', 'rowId', 'activeRows'], // dataLength je v primeri, če je AJAX active, ka znaš, kelko strani trbe kreerati. RowID je v primeri, če je field clickable.
  components: { CustomTablePagination },
  methods: {
    filteredLabel (label) {
      if (typeof label !== 'undefined' && label !== null) {
        const newLabel = label.toString()
        const regExp = new RegExp(this.search, 'gi')
        return newLabel.replace(regExp, '<b>$&</b>')
      }

      return label
    },

    rowClicked (row) {
      const cell = row.find(cell => cell.field === this.rowId)

      if (typeof cell !== 'undefined' && cell !== null) {
        this.$emit('row-click', cell.value)
      }
    },

    numItemsChanged (numItems) {
      this.itemsToShow = numItems
      this.$emit('num-items-change', numItems)
    },

    pageChanged (page) {
      this.currentPage = page
      this.$emit('page-change', page)
    },

    getPage () {
      return this.currentPage
    },

    getNumItems () {
      return this.itemsToShow
    }
  },
  computed: {
    pagedData () {
      if (this.dataLength) {
        return this.filteredData
      }

      const pagedData = []
      const startIndex = (this.currentPage - 1) * this.itemsToShow
      const endIndex = startIndex + this.itemsToShow

      for (let i = startIndex; i < endIndex; i++) {
        pagedData.push(this.filteredData[i])
      }

      return pagedData
    },
    filteredData () {
      const data = this.data.map(row => {
        const newRow = row.map(field => {
          return {
            label: field.value,
            ...field
          }
        })

        return [...newRow]
      })

      let filteredData = [...data]

      if (typeof this.search !== 'undefined' && this.search !== null && this.search !== '') {
        filteredData = []

        data.forEach(row => {
          for (let i = 0; i < row.length; i++) {
            const field = row[i]

            if (field.value !== null && typeof field.value !== 'undefined' && field.value.toString().toLowerCase().includes(this.search.toLowerCase())) {
              filteredData.push(row)
              break
            }
          }
        })
      }

      return filteredData
    }
  }
}
</script>
<style scoped>
.custom-table {
  width: 100%;
  border: none;
}

.custom-table th {
  padding: 10px 16px;
  text-align: left;
}

.custom-table tr {
  cursor: pointer;
}

.custom-table tr:hover {
  background-color: #F6F6F6;
}

.custom-table tr td {
  padding: 8px 16px;
  border-top: 1px solid #EEEEEE;
}
</style>
