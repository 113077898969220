import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import axios from 'axios'
import fileDownload from 'js-file-download'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.tracker.aeroklub-ms.si/' : 'http://192.168.1.228:8000/'

Vue.config.productionTip = false

const axiosInstance = axios.create({
  baseURL: baseURL
})

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.prototype.$axios = axiosInstance
Vue.prototype.$fileDownload = fileDownload

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
