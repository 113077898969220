<template>
  <v-container class="d-flex" :style="'width: 100%; max-width: 100%; height: 100%; padding: 0; flex-direction: ' + ((drawerPosition === 'right') ? 'row' : 'column')">
    <div class="custom-map-buttons" :style="mapButtonsStyle">
      <template v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md'">
        <div>
          <v-btn color="white" tile fab small class="elevation-0" @click.stop="toggleNavigationDrawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        <div class="custom-map-buttons-divider"></div>
      </template>
      <div>
        <v-btn color="white" tile fab small class="elevation-0" @click.stop="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
      <div class="custom-map-buttons-divider"></div>
      <div>
        <v-btn color="white" tile fab small class="elevation-0" @click.stop="drawer = !drawer">
          <v-icon>mdi-resize</v-icon>
        </v-btn>
      </div>
    </div>
    <google-maps :style="gmapStyle" :planesPoints="planesPointsMaps" :colorful="colorMaps" :live="liveView" :airports="true" v-on:hover="point => pointSelectionChangedEvent(point)" ref="gmaps"></google-maps>
    <custom-drawer :style="drawerStyle" v-if="drawer">
      <v-container style="width: 100%; max-width: 100%; height: 0px;"> <!-- height: 0px je tu hack. -->
        <v-row>
          <v-col class="pt-5" cols="auto" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-btn depressed @click="toggleLayoutOrientation">
              <v-icon>{{ (layoutPosition === "horizontal") ? "mdi-view-split-horizontal" : "mdi-view-split-vertical" }}</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-spacer v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"></v-spacer>
          <v-col cols="auto" class="pt-5">
            <v-btn depressed :color="liveView ? 'success' : ''" @click="liveView = !liveView">Live</v-btn>
          </v-col>
          <v-col cols="12" xs="12" :sm="(drawerPosition === 'right') ? '12' : '3'" class="pt-0 pb-0">
            <v-row>
              <template v-if="liveView">
                <v-col>
                  <v-select v-model="historyRange" :items="timeHistoryArray" item-text="label" item-value="value" label="Rang"></v-select>
                </v-col>
              </template>
              <template v-else>
                <v-col xs="6">
                  <date-picker label="Datum" :value="dateTime" @input="date => dateTime = date"></date-picker>
                </v-col>
              </template>
            </v-row>
          </v-col> -->
          <v-col class="pt-5" cols="auto">
            <v-btn depressed :color="liveView ? 'success' : ''" @click="liveView = !liveView">V živo</v-btn>
          </v-col>
          <template v-if="liveView">
            <v-col :cols="(layoutPosition === 'horizontal') ? '' : 'auto'">
              <v-select v-model="historyRange" :items="timeHistoryArray" item-text="label" item-value="value" label="Rang"></v-select>
            </v-col>
          </template>
          <template v-else>
            <v-col :cols="(layoutPosition === 'horizontal') ? '' : 'auto'" class="pt-2">
              <date-picker label="Datum" :value="dateTime" :allowed-dates="allowedDates" @input="date => dateTime = date"></date-picker>
            </v-col>
          </template>
          <v-col cols="auto" class="pt-5" v-if="!liveView">
            <v-btn depressed @click="exportDataForSelectedDate" :disabled="exportButtonDisabled || !canExport" v-if="user && user.role > 2">Izvozi</v-btn>
          </v-col>
          <v-col cols="12" xs="12" :sm="(drawerPosition === 'right') ? '12' : '3'" class="pt-0 pb-0">
            <v-row v-if="imeis.length > 0">
              <v-col cols="8" class="pt-5">
                <custom-select v-model="selectedImeis" :items="getPlanesByImeis(imeis)" item-value="imei" :item-text="plane => plane.name + ' / ' + plane.registration" attach label="Izbira letal" outlined dense></custom-select>
              </v-col>
              <v-col cols="auto" class="pt-2">
                <v-checkbox v-model="colorMaps" label="Barvno"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" :sm="(drawerPosition === 'right') ? '12' : '3'" class="pt-0 pb-0" v-if="liveView">
            <v-row v-if="imeis.length > 0">
              <v-col cols="8" class="pt-5">
                <v-select v-model="imeiToFollow" :items="getPlanesByImeis(selectedImeis)" item-value="imei" :item-text="plane => plane.name + ' / ' + plane.registration" attach label="Sledenje" outlined dense></v-select>
              </v-col>
              <v-col cols="auto" class="pt-2">
                <v-checkbox v-model="followImei" label="Sledi"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="planesPoints.length > 0">
          <v-col cols="12" class="mb-4" v-for="imei in selectedImeis" :key="'chart' + imei">
            <line-chart :title="chartTitleForImei(imei)" :imei="imei" :data="chartDataForImei(imei)" @click="point => chartPointClickedEvent(point)" @hover="point => pointSelectionChangedEvent(point)" :ref="'chart' + imei"></line-chart>
          </v-col>
        </v-row>
      </v-container>
    </custom-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoogleMaps from '@/components/GoogleMaps'
import LineChart from '@/components/LineChart'
import DatePicker from '@/components/DatePicker'
import CustomDrawer from '@/components/CustomDrawer'
import CustomSelect from '@/components/CustomSelect'

export default {
  data: () => ({
    drawer: true,
    drawerStyle: '',
    gmapStyle: '',
    mapButtonsStyle: '',
    screenOrientation: (window.innerWidth > window.innerHeight) ? 'portrait' : 'landscape',
    users: [],
    planes: [],
    imeis: [],
    liveView: false,
    selectedImeis: [],
    planesPoints: [],
    canExport: true, // To je povezano s timeron, ka kda klikneš export, te je določeni timeout
    dateTime: new Date((new Date()).setHours(0, 0, 0, 0)),
    flightDates: [], // Datumi, ko se je letelo
    colorMaps: false,
    historyRange: 10,
    refreshRate: 5,
    liveTimer: null,
    imeiToFollow: null,
    isEavio: false, // To je za EAVIO redirect
    axiosOptions: null, // To je za EAVIO redirect
    followImei: false,
    gmapsWidth: '',
    layoutPosition: 'horizontal',
    timeHistoryArray: [
      { value: 1, label: '1 min' },
      { value: 10, label: '10 min' },
      { value: 30, label: '30 min' },
      { value: 60, label: '1 ura' },
      { value: 120, label: '2 uri' },
      { value: 180, label: '3 ure' },
      { value: 300, label: '5 ur' }
    ]
  }),
  watch: {
    drawerPosition: function (newVal, oldVal) {
      const position = newVal

      this.setLayoutDimensions(position)

      this.selectedImeis.forEach(imei => {
        this.$refs['chart' + imei][0].resize()
      })
    },
    dateTime: function (newVal, oldVal) {
      this.getInitialData()
    },
    historyRange: function (newVal, oldVal) {
      this.stopLiveTimer()
      this.getInitialData()
    },
    liveView: function (newVal, oldVal) {
      this.imeis = []
      this.selectedImeis = []
      this.planesPoints = []

      if (newVal === false) {
        this.stopLiveTimer()
      }

      this.getInitialData()
    },
    selectedImeis: function (newVal, oldVal) {
      if (!newVal.includes(this.imeiToFollow)) {
        this.imeiToFollow = null
      }
    }
  },
  components: { GoogleMaps, LineChart, DatePicker, CustomDrawer, CustomSelect },
  mounted () {
    if (this.$route.name === 'EavioMap') {
      this.isEavio = true

      this.axiosOptions = {
        headers: {
          eavio: this.$route.params.hash
        }
      }
    }

    this.getInitialData()
    this.setLayoutDimensions(this.drawerPosition)

    window.addEventListener(
      'orientationchange',
      () => {
        if (window.innerWidth > window.innerHeight) {
          this.screenOrientation = 'landscape'
        } else {
          this.screenOrientation = 'portrait'
        }
      }
    )
  },
  methods: {
    ...mapActions('common', ['toggleNavigationDrawer']),

    async getInitialData () {
      const axios = this.$axios

      if (this.dateTime === null && this.liveView !== true) {
        return
      }

      this.planesPoints = []

      let response = await axios.get('planes', this.axiosOptions)
      this.planes = [...response.data.result]

      response = await axios.get('users', this.axiosOptions)
      this.users = [...response.data.result]

      let timestampFrom = null
      let timestampTo = null
      let dataURL = ''

      if (this.liveView === true) {
        const dateTimeNow = this.getUTCDate()
        dateTimeNow.setMinutes(dateTimeNow.getMinutes() - this.historyRange)
        timestampFrom = Math.round(dateTimeNow.getTime() / 1000)
        dataURL = `data/${timestampFrom}`
      } else if (this.dateTime !== null) {
        timestampFrom = (new Date(this.dateTime)).getTime() / 1000
        timestampTo = new Date(this.dateTime)
        timestampTo.setDate(timestampTo.getDate() + 1)
        timestampTo = timestampTo.getTime() / 1000
        dataURL = `data/${timestampFrom}/${timestampTo}`
      } else {
        console.log('Returnam')
        return
      }

      axios.get(dataURL, this.axiosOptions)
        .then(response2 => {
          const data = response2.data.result
          const planesPoints = {}
          const planesNamesByImei = {}
          const userNamesByIbutton = {}
          const planesSet = new Set()

          data.forEach(entry => {
            planesSet.add(entry[0])
          })

          this.imeis = Array.from(planesSet)
          this.selectedImeis = [...this.imeis]

          for (const imei of this.imeis) {
            planesPoints[imei] = []

            const plane = this.planes.find(plane => plane.imei === imei)

            planesNamesByImei[imei] = (typeof plane !== 'undefined' && plane !== null) ? plane.name + ' / ' + plane.registration : ''
          }

          this.users.forEach(user => {
            if (user.ibutton) {
              userNamesByIbutton[user.ibutton.toUpperCase()] = user.firstname + ' ' + user.lastname
            }
          })

          for (const entry of data) {
            const mappedEntry = {
              plane: planesNamesByImei[entry[0]],
              imei: entry[0],
              timestamp: new Date(parseInt(entry[1]) * 1000),
              longitude: entry[2],
              latitude: entry[3],
              altitude: entry[4],
              speed: entry[5],
              angle: entry[6],
              ground_altitude: entry[7],
              ibutton: entry[8],
              pilot: 'Neznan pilot'
            }

            if (entry[8] && entry[8].toUpperCase() in userNamesByIbutton) {
              mappedEntry.pilot = userNamesByIbutton[entry[8].toUpperCase()]
            }

            planesPoints[entry[0]].push(mappedEntry)
          }

          for (const [key, value] of Object.entries(planesPoints)) {
            this.planesPoints.push({
              imei: key,
              points: value
            })
          }

          const bounds = this.getFlightCoordinateBounds()
          this.$refs.gmaps.setMapPositionToBounds(bounds.minLat, bounds.minLng, bounds.maxLat, bounds.maxLng)

          if (this.liveView === true) {
            this.startLiveTimer()
          }
        })
        .catch(error => {
          console.log(error)
        })

      axios.get('flight-dates', this.axiosOptions)
        .then(response => {
          const data = response.data.result

          this.flightDates = data.map(item => {
            return item[0]
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    allowedDates (value) {
      return this.flightDates.includes(value)
    },
    setLayoutDimensions (position) {
      if (position === 'right') {
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.drawerStyle = 'width: 50%;'
        } else {
          this.drawerStyle = 'width: 500px;'
        }

        this.mapButtonsStyle = 'position: absolute; bottom: 20px; left: 10px; z-index: 10;'
        this.gmapStyle = 'flex-grow: 1; height: 100%;'
      } else {
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.drawerStyle = 'height: 50%;'
        } else {
          this.drawerStyle = 'height: 400px;'
        }

        this.mapButtonsStyle = 'position: absolute; top: 20px; right: 10px; z-index: 10;'
        this.gmapStyle = 'flex-grow: 1; width: 100%;'
      }
    },
    exportDataForSelectedDate () {
      const timestampFrom = (new Date(this.dateTime)).getTime() / 1000
      let timestampTo = new Date(this.dateTime)
      timestampTo.setDate(timestampTo.getDate() + 1)
      timestampTo = timestampTo.getTime() / 1000
      const dataURL = `data/export/${timestampFrom}/${timestampTo}`

      this.$axios.get(dataURL, { responseType: 'blob' })
        .then(response => {
          const contentDisposition = response.headers['content-disposition']
          const fileName = contentDisposition.split('attachment; filename=')[1]
          this.$fileDownload(response.data, fileName)
          this.canExport = false

          setTimeout(() => {
            this.canExport = true
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    toggleLayoutOrientation () {
      if (this.layoutPosition === 'horizontal') {
        this.layoutPosition = 'vertical'
      } else {
        this.layoutPosition = 'horizontal'
      }
    },
    chartTitleForImei (imei) {
      const plane = this.planes.find(plane => plane.imei === imei)
      let title = imei

      if (typeof plane !== 'undefined' && plane !== null) {
        title = plane.name + ' (' + plane.registration + ')'
      }

      const planesPointsByImei = this.planesPoints.find(entry => entry.imei === imei)

      if (planesPointsByImei.points.length > 0) {
        const lastPlanePoint = planesPointsByImei.points[planesPointsByImei.points.length - 1]
        const ibutton = lastPlanePoint.ibutton

        const user = this.users.find(user => typeof user.ibutton !== 'undefined' && ibutton.toUpperCase() === user.ibutton.toUpperCase())

        if (user && !this.isEavio) {
          title = title + ' | ' + user.firstname + ' ' + user.lastname
        }
      }

      return title
    },
    chartDataForImei (imei) {
      const planesPointsByImei = this.planesPoints.find(entry => entry.imei === imei)

      const data = []

      planesPointsByImei.points.forEach(point => {
        const dataPoints = [
          point.timestamp,
          point.altitude,
          point.speed,
          point.ground_altitude
        ]

        data.push(dataPoints)
      })

      return data
    },
    pointSelectionChangedEvent (point) {
      this.$refs.gmaps.clearMarkerAndTooltip()

      if (this.drawer) {
        this.selectedImeis.forEach(imei => {
          this.$refs['chart' + imei][0].clear()
        })
      }

      if (point !== null) {
        let newPoint = null

        for (const [index, entry] of this.planesPoints.entries()) {
          newPoint = entry.points.find(p => p.timestamp.getTime() === point.timestamp.getTime() && p.imei === point.imei)

          if (newPoint !== null && typeof newPoint !== 'undefined') {
            break
          }
        }

        newPoint.lat = newPoint.latitude
        newPoint.lng = newPoint.longitude

        this.$refs.gmaps.showMarkerAndTooltip(newPoint)

        if (this.drawer) {
          if (!this.isEavio) {
            this.$refs['chart' + point.imei][0].updateTitle(newPoint)
          }

          this.$refs['chart' + point.imei][0].select(newPoint)
        }
      }
    },
    chartPointClickedEvent (point) {
      if (point !== null) {
        let newPoint = null

        for (const [index, entry] of this.planesPoints.entries()) {
          newPoint = entry.points.find(p => p.timestamp.getTime() === point.timestamp.getTime() && p.imei === point.imei)

          if (newPoint !== null && typeof newPoint !== 'undefined') {
            break
          }
        }

        this.$refs.gmaps.clearMarkerAndTooltip()
        this.$refs.gmaps.showMarkerAndTooltip(newPoint)
        this.$refs.gmaps.moveTo(newPoint)
      }
    },
    startLiveTimer () {
      this.liveTimer = setInterval(() => {
        const axios = this.$axios

        const dateTimeNow = this.getUTCDate()
        dateTimeNow.setMinutes(dateTimeNow.getMinutes() - this.historyRange)

        const planesDates = []

        this.planesPoints.forEach(planesPoint => {
          const ts = planesPoint.points[planesPoint.points.length - 1].timestamp
          const ts2 = dateTimeNow

          if (ts > ts2) {
            planesDates.push({
              imei: planesPoint.imei,
              timestamp: ts.getTime() / 1000
            })
          }
        })

        axios.post('data/new', { date_from: dateTimeNow.getTime() / 1000, planesDates }, this.axiosOptions)
          .then(response => {
            const data = response.data.result
            const planesPoints = {}
            const planesNamesByImei = {}
            const imeisSet = new Set()

            data.forEach(entry => {
              imeisSet.add(entry[0])
            })

            const imeis = Array.from(imeisSet)

            for (const imei of imeis) {
              planesPoints[imei] = []

              const plane = this.planes.find(plane => plane.imei === imei)

              planesNamesByImei[imei] = (typeof plane !== 'undefined' && plane !== null) ? plane.name + ' / ' + plane.registration : ''
            }

            for (const entry of data) {
              const mappedEntry = {
                plane: planesNamesByImei[entry[0]],
                imei: entry[0],
                timestamp: new Date(parseInt(entry[1]) * 1000),
                longitude: entry[2],
                latitude: entry[3],
                altitude: entry[4],
                speed: entry[5],
                angle: entry[6],
                ground_altitude: entry[7],
                ibutton: entry[8]
              }

              planesPoints[entry[0]].push(mappedEntry)
            }

            for (const [key, value] of Object.entries(planesPoints)) {
              const planePoints = this.planesPoints.find(entry => entry.imei === key)

              if (typeof planePoints !== 'undefined') {
                planePoints.points = [...planePoints.points, ...value]
              } else {
                this.planesPoints.push({
                  imei: key,
                  points: value
                })
              }
            }

            for (let i = 0; i < this.planesPoints.length; i++) {
              this.planesPoints[i].points = this.planesPoints[i].points.filter(entry => entry.timestamp >= dateTimeNow)
            }

            this.planesPoints = this.planesPoints.filter(entry => entry.points.length > 0)

            this.imeis = this.planesPoints.map(entry => entry.imei)
            this.selectedImeis = this.selectedImeis.filter(entry => this.imeis.includes(entry))

            if (this.selectedImeis.length > 0 && this.followImei === true && this.imeiToFollow !== '' && typeof this.imeiToFollow !== 'undefined') {
              const planePoints = this.planesPoints.find(entry => entry.imei === this.imeiToFollow)

              if (typeof planePoints !== 'undefined') {
                const newPoint = planePoints.points[planePoints.points.length - 1]
                this.$refs.gmaps.moveTo(newPoint)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }, this.refreshRate * 1000)
    },
    stopLiveTimer () {
      if (this.liveTimer !== null) {
        clearInterval(this.liveTimer)
        this.liveTimer = null
      }
    },
    getUTCDate () {
      const date = new Date()

      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    },
    getPlanesByImeis (imeis) {
      const planes = []

      imeis.forEach(imei => {
        const plane = this.planes.find(plane => plane.imei === imei)

        if (typeof plane !== 'undefined' && plane !== null) {
          planes.push(plane)
        } else {
          planes.push(imei)
        }
      })

      return planes
    },

    getFlightCoordinateBounds () {
      if (this.planesPoints.length === 0) {
        return
      }

      let minLat = this.planesPoints[0].points[0].latitude
      let minLng = this.planesPoints[0].points[0].longitude
      let maxLat = this.planesPoints[0].points[0].latitude
      let maxLng = this.planesPoints[0].points[0].longitude

      for (const [index, entry] of this.planesPoints.entries()) {
        entry.points.forEach(point => {
          if (minLat > point.latitude) {
            minLat = point.latitude
          }

          if (minLng > point.longitude) {
            minLng = point.longitude
          }

          if (maxLat < point.latitude) {
            maxLat = point.latitude
          }

          if (maxLng < point.longitude) {
            maxLng = point.longitude
          }
        })
      }

      return {
        minLat, minLng, maxLat, maxLng
      }
    },

    refresh () {
      location.reload()
    }
  },
  computed: {
    ...mapGetters('common', ['user']),

    exportButtonDisabled () {
      return this.planesPoints.length === 0
    },
    planesPointsMaps () {
      const planesPoints = []

      for (const [index, entry] of this.planesPoints.entries()) {
        if (this.selectedImeis.some(imei => imei === entry.imei) && entry.points.length > 0) {
          const mappedPoints = entry.points.map(point => {
            return {
              lat: point.latitude,
              lng: point.longitude,
              ...point
            }
          })

          const plane = this.planes.find(plane => plane.imei === entry.imei)

          planesPoints.push({
            imei: entry.imei,
            color: (typeof plane !== 'undefined') ? plane.color : '#00FF00',
            points: mappedPoints
          })
        }
      }

      return planesPoints
    },
    drawerPosition () {
      let position = 'right'

      if (this.$vuetify.breakpoint.name === 'xs') {
        if (this.screenOrientation === 'landscape') {
          position = 'bottom'
        } else {
          position = 'right'
        }
      } else {
        if (this.layoutPosition === 'vertical') {
          position = 'bottom'
        } else {
          position = 'right'
        }
      }

      return position
    }
  }
}
</script>

<style scoped>
.custom-map-buttons {
  box-shadow: 0 0 5px #CCC;
}

.custom-map-buttons-divider {
  height: 1px;
  background-color: #DDDDDD;
}
</style>
