export default {
  setUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setUser', payload)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  toggleNavigationDrawer ({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('toggleNavigationDrawer')
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  setNavigationDrawer ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setNavigationDrawer', payload)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  setFilteredFlightsList ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('setFilteredFlightsList', payload)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  addToFilteredFlightsVisitedList ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit('addToFilteredFlightsVisitedList', payload)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  },
  resetFilteredFlightsVisitedList ({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('resetFilteredFlightsVisitedList')
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }
}
