<template>
  <v-app id="inspire" style="background-color: #EEEEEE">
    <v-app-bar app clipped-right flat height="72" color="white" v-if="($route.name !== 'flightid' && $route.name !== 'map')">
      <v-app-bar-nav-icon @click.stop="toggleNavigationDrawer"></v-app-bar-nav-icon>
      <v-btn icon @click="refresh" v-if="navigator === 'AKMS'">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      :value="navigationDrawer"
      @input="value => setNavigationDrawer(value)"
      app
      disable-resize-watcher
      width="300"
      v-if="user"
    >
      <v-app-bar
        height="72"
        flat
        color="blue"
        dark
      >
        <span class="display-1 font-weight-medium">AK</span><span class="display-1 font-weight-light">MS</span>
      </v-app-bar>
      <div class="sidebar-header-background"></div>
      <div class="sidebar-avatar-container">
        <div class="sidebar-avatar">
          <div class="sidebar-avatar-initials display-2">{{ userInitials }}</div>
        </div>
      </div>
      <div class="sidebar-user-details">
        <div class="sidebar-user-fullname font-weight-medium">
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div class="sidebar-user-email font-weight-light">
          {{ user.email }}
        </div>
      </div>
      <v-list v-if="user">
        <v-list-item :to="isEavio ? '/eavio/' + eavioHash + '/map' : '/map'">
          <v-list-item-icon>
            <v-icon>mdi-google-maps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zemljevid</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="isEavio ? '/eavio/' + eavioHash + '/flights' : '/flights'">
          <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dnevnik</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/planes" v-if="user.role > 2">
          <v-list-item-icon>
            <v-icon>mdi-airplane</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Letala</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/imeis" v-if="user.role > 2">
          <v-list-item-icon>
            <v-icon>mdi-satellite-uplink</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>IMEI</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/users" v-if="user.role > 2">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Uporabniki</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ibuttons" v-if="user.role > 2">
          <v-list-item-icon>
            <v-icon>mdi-sd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>IButtoni</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item to="/settings" v-if="user.role > 2">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nastavitve</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item style="margin-bottom: 70px;" @click="logout" v-if="user.role > 1">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Odjava</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    navigator: null,
    isEavio: false,
    eavioHash: null,
    axiosOptions: null
  }),
  mounted () {
    if (this.$route.name === 'EavioMap' || this.$route.name === 'EavioFlights') {
      this.isEavio = true
      this.eavioHash = this.$route.params.hash
      this.axiosOptions = {
        headers: {
          eavio: this.$route.params.hash
        }
      }
    }

    this.$axios.get('/me', this.axiosOptions)
      .then(response => {
        const data = response.data.result

        if (data !== null && data !== '') {
          const user = {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            role: data.role
          }

          this.setUser(user)

          if (this.$router.currentRoute.path === '' || this.$router.currentRoute.path === '/') {
            if (user.role === 1) {
              this.$router.push('/flights')
            } else {
              this.$router.push('/map')
            }
          }
        }
      })
      .catch(error => {
        this.$router.push('/login')
        console.log(error)
      })

    if (this.$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm' && this.$vuetify.breakpoint.name !== 'md') {
      this.setNavigationDrawer(true)
    }

    this.navigator = navigator.userAgent
  },
  methods: {
    ...mapActions('common', ['setUser', 'toggleNavigationDrawer', 'setNavigationDrawer']),

    refresh () {
      location.reload()
    },

    logout () {
      localStorage.removeItem('jwtToken')
      this.setUser(null)
      // this.$router.push('/login')
      location.reload()
    }
  },
  computed: {
    ...mapGetters('common', ['user', 'navigationDrawer']),

    userInitials () {
      return this.user.firstname[0] + (this.user.lastname[0] ? this.user.lastname[0] : '')
    }
  }
}
</script>
<style>
@supports (-webkit-touch-callout: none) {
  .v-application--wrap {
    min-height: -webkit-fill-available !important;
    max-height: -webkit-fill-available !important;
  }
}

.dygraph-legend {
  left: 50px !important;
  top: 10px !important;
  width: 350px !important;
  z-index: 0 !important;
}

.dygraph-title {
  margin-top: -20px; /* To je praktično hack */
  font-size: 12pt;
}

.sidebar-header-background {
  height: 120px;
  background-color: #0c8af0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 20px));
}

.sidebar-avatar-container {
  position: relative;
  margin: -80px auto 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: white;
}

.sidebar-avatar {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 116px;
  height: 116px;
  border-radius: 58px;
  background-color: #CCC;
}

.sidebar-avatar-initials {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.sidebar-user-details {
  margin: 10px 0 20px 0;
}

.sidebar-user-details div{
  text-align: center;
}

.v-menu__content {
  max-height: 550px !important;
}

.custom-select-height-250 .v-menu__content {
  max-height: 250px !important;
}
</style>
