<template>
  <v-container class="d-flex" :style="'width: 100%; max-width: 100%; height: 100%; padding: 0; flex-direction: ' + ((drawerPosition === 'right') ? 'row' : 'column')" v-if="plane">
    <v-app-bar app clipped-right flat height="72" color="white">
      <v-app-bar-nav-icon @click.stop="toggleNavigationDrawer"></v-app-bar-nav-icon>
      <v-btn icon @click="refresh" v-if="navigator === 'AKMS'">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-btn icon @click="$router.push('/flights')">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn icon @click="goToNextFlight" :disabled="!nextFlightAvailable">
        <v-icon>
          mdi-chevron-down
        </v-icon>
      </v-btn>
      <v-btn icon @click="goToPreviousFlight" :disabled="!previousFlightAvailable">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-app-bar>
    <div class="custom-map-buttons" :style="mapButtonsStyle">
      <template v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
        <div>
          <v-btn color="white" tile fab small class="elevation-0" @click.stop="toggleNavigationDrawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        <div class="custom-map-buttons-divider"></div>
      </template>
      <div>
        <v-btn color="white" tile fab small class="elevation-0" @click.stop="drawer = !drawer">
          <v-icon>mdi-resize</v-icon>
        </v-btn>
      </div>
    </div>
    <google-maps :style="gmapStyle" :planesPoints="planesPointsMaps" :colorful="colorMaps" :airports="true" v-on:hover="point => pointSelectionChangedEvent(point)" ref="gmaps"></google-maps>
    <custom-drawer :style="drawerStyle" v-if="drawer">
      <v-container style="width: 100%; max-width: 100%; height: 0px;"> <!-- height: 0px je tu hack. -->
        <v-row>
          <v-col :class="['flight-data', (drawerPosition !== 'right') ? 'flight-data-dense' : '']" v-if="pilot">
            <div>
              <v-icon class="mr-4 mb-1">mdi-account</v-icon>
              {{ pilot }}
            </div>
            <div>
              <v-icon class="mr-4 mb-1">mdi-airplane</v-icon>
              {{ plane.name + ' / ' + plane.registration }}
            </div>
            <div>
              <v-icon class="mr-4 mb-1">mdi-google-maps</v-icon>
              {{ flightPurpose }}
            </div>
            <div>
              <v-icon class="mr-4 mb-1">mdi-calendar</v-icon>
              {{ flightDate }}
            </div>
            <div>
              <v-icon class="mr-4 mb-1">mdi-timer-outline</v-icon>
              {{ getFlightTime(flightStartTime, flightEndTime) }}
            </div>
          </v-col>
          <v-col cols="auto" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-btn depressed @click="toggleLayoutOrientation">
              <v-icon>{{ (layoutPosition === "horizontal") ? "mdi-view-split-horizontal" : "mdi-view-split-vertical" }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="planesPoints.length > 0">
          <v-col cols="12" class="mt-5">
            <line-chart title=" " :imei="plane.imei" :data="chartDataForImei(plane.imei)" @click="point => chartPointClickedEvent(point)" @hover="point => pointSelectionChangedEvent(point)" :ref="'chart' + plane.imei"></line-chart>
          </v-col>
          <v-col cols="12">
            <v-row v-if="events">
              <v-col cols="12" class="mt-5">
                <v-list rounded>
                  <v-subheader>Dogodki</v-subheader>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item v-for="(event, i) in events" :key="'event' + i" @click="eventClicked(event)">
                    <v-list-item-content>
                      <v-list-item-title v-text="getEventNameByType(event.type)"></v-list-item-title>
                      <v-list-item-subtitle v-text="event.remarks"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="text--primary" v-text="getTimeByTimestamp(event.timestamp)"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-if="warnings">
              <v-col cols="12" class="mt-5">
                <v-list rounded>
                  <v-subheader>Opozorila<v-icon class="ml-5" color="red">mdi-alert</v-icon></v-subheader>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item v-for="(warning, i) in warnings" :key="'warning' + i" @click="eventClicked(warning)">
                    <v-list-item-content>
                      <v-list-item-title v-text="getWarningNameByType(warning.type)"></v-list-item-title>
                      <v-list-item-subtitle v-text="warning.comment"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="text--primary" v-text="getTimeByTimestamp(warning.timestamp)"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </custom-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoogleMaps from '@/components/GoogleMaps'
import LineChart from '@/components/LineChart'
import DatePicker from '@/components/DatePicker'
import CustomDrawer from '@/components/CustomDrawer'
import CustomSelect from '@/components/CustomSelect'

export default {
  data: () => ({
    drawer: true,
    drawerStyle: '',
    gmapStyle: '',
    mapButtonsStyle: '',
    screenOrientation: (window.innerWidth > window.innerHeight) ? 'portrait' : 'landscape',
    plane: null,
    pilot: null,
    flightPurpose: null,
    flightDate: null,
    flightStartTime: null,
    flightEndTime: null,
    planesPoints: [],
    dateTime: new Date((new Date()).setHours(0, 0, 0, 0)),
    flightDates: [], // Datumi, ko se je letelo
    colorMaps: false,
    gmapsWidth: '',
    layoutPosition: 'horizontal',
    events: null,
    warnings: null,
    navigator: null
  }),
  watch: {
    drawerPosition: function (newVal, oldVal) {
      const position = newVal

      this.setLayoutDimensions(position)

      this.$refs['chart' + this.plane.imei].resize()
    },
    dateTime: function (newVal, oldVal) {
      this.getInitialData()
    },
    selectedImeis: function (newVal, oldVal) {
      if (!newVal.includes(this.imeiToFollow)) {
        this.imeiToFollow = null
      }
    }
  },
  components: { GoogleMaps, LineChart, CustomDrawer },
  mounted () {
    this.getInitialData()
    this.setLayoutDimensions(this.drawerPosition)

    window.addEventListener(
      'orientationchange',
      () => {
        if (window.innerWidth > window.innerHeight) {
          this.screenOrientation = 'landscape'
        } else {
          this.screenOrientation = 'portrait'
        }
      }
    )

    this.addToFilteredFlightsVisitedList(this.$route.params.id)

    this.navigator = navigator.userAgent
  },
  methods: {
    ...mapActions('common', ['toggleNavigationDrawer', 'addToFilteredFlightsVisitedList']),

    refresh () {
      location.reload()
    },

    async getInitialData () {
      const axios = this.$axios

      if (this.dateTime === null) {
        return
      }

      this.planesPoints = []

      let dataURL = ''

      if (this.dateTime !== null) {
        if (this.$route.params.hash) {
          dataURL = `flight/data/${this.$route.params.hash}`
        } else {
          dataURL = `flight/data/id/${this.$route.params.id}`
        }
      } else {
        return
      }

      axios.get(dataURL)
        .then(response2 => {
          const data = response2.data.result

          if (!('points' in data)) {
            window.location.href = 'https://akms.eavio.club/'
          }

          const planesPoints = {}
          planesPoints[data.plane.imei] = []

          this.plane = {
            name: data.plane.name,
            registration: data.plane.registration,
            imei: data.plane.imei,
            color: data.plane.color
          }

          this.pilot = data.flight.pilot
          this.flightDate = data.flight.flight_date
          this.flightPurpose = data.flight.purpose
          this.flightStartTime = new Date(data.flight.start_timestamp)
          this.flightEndTime = new Date(data.flight.end_timestamp)

          for (const entry of data.points) {
            const mappedEntry = {
              plane: this.plane.name + ' / ' + this.plane.registration,
              imei: this.plane.imei,
              timestamp: new Date(parseInt(entry[1]) * 1000),
              longitude: entry[2],
              latitude: entry[3],
              altitude: entry[4],
              speed: entry[5],
              angle: entry[6],
              ground_altitude: entry[7]
            }

            planesPoints[entry[0]].push(mappedEntry)
          }

          const tpt = []

          for (const [key, value] of Object.entries(planesPoints)) {
            tpt.push({
              imei: key,
              points: value
            })
          }

          setTimeout(() => {
            this.planesPoints = tpt

            const bounds = this.getFlightCoordinateBounds()

            this.$refs.gmaps.setMapPositionToBounds(bounds.minLat, bounds.minLng, bounds.maxLat, bounds.maxLng)

            if ('events' in data && data.events.length > 0) {
              this.events = data.events.map(event => {
                return {
                  type: event.type,
                  remarks: event.remarks,
                  timestamp: new Date(event.timestamp)
                }
              })
            }

            if ('warnings' in data && data.warnings.length > 0) {
              this.warnings = data.warnings.map(warning => {
                return {
                  type: warning.type,
                  comment: warning.comment,
                  timestamp: new Date(warning.timestamp)
                }
              })
            }
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          window.location.href = 'https://akms.eavio.club/'
        })
    },
    allowedDates (value) {
      return this.flightDates.includes(value)
    },
    setLayoutDimensions (position) {
      if (position === 'right') {
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.drawerStyle = 'width: 50%;'
        } else {
          this.drawerStyle = 'width: 500px;'
        }

        this.mapButtonsStyle = 'position: absolute; bottom: 20px; left: 10px; z-index: 10;'
        this.gmapStyle = 'flex-grow: 1; height: 100%;'
      } else {
        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
          this.drawerStyle = 'height: 50%;'
        } else {
          this.drawerStyle = 'height: 400px;'
        }

        this.mapButtonsStyle = 'position: absolute; top: 20px; right: 10px; z-index: 10;'
        this.gmapStyle = 'flex-grow: 1; width: 100%;'
      }
    },
    exportDataForSelectedDate () {
      const timestampFrom = (new Date(this.dateTime)).getTime() / 1000
      let timestampTo = new Date(this.dateTime)
      timestampTo.setDate(timestampTo.getDate() + 1)
      timestampTo = timestampTo.getTime() / 1000
      const dataURL = `data/export/${timestampFrom}/${timestampTo}`

      this.$axios.get(dataURL, { responseType: 'blob' })
        .then(response => {
          const contentDisposition = response.headers['content-disposition']
          const fileName = contentDisposition.split('attachment; filename=')[1]
          this.$fileDownload(response.data, fileName)
          this.canExport = false

          setTimeout(() => {
            this.canExport = true
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    toggleLayoutOrientation () {
      if (this.layoutPosition === 'horizontal') {
        this.layoutPosition = 'vertical'
      } else {
        this.layoutPosition = 'horizontal'
      }
    },
    chartTitleForImei (imei) {
      let title = imei

      if (typeof this.plane !== 'undefined' && this.plane !== null) {
        title = this.plane.name + ' / ' + this.plane.registration
      }

      return title
    },
    chartDataForImei (imei) {
      const planesPointsByImei = this.planesPoints.find(entry => entry.imei === imei)

      const data = []

      planesPointsByImei.points.forEach(point => {
        const dataPoints = [
          point.timestamp,
          point.altitude,
          point.speed,
          point.ground_altitude
        ]

        data.push(dataPoints)
      })

      return data
    },
    pointSelectionChangedEvent (point) {
      if (typeof point === 'undefined' || typeof this.$refs['chart' + this.plane.imei] === 'undefined') {
        return
      }

      this.$refs.gmaps.clearMarkerAndTooltip()

      if (this.drawer) {
        this.$refs['chart' + this.plane.imei].clear()
      }

      if (point !== null) {
        let newPoint = null

        for (const [index, entry] of this.planesPoints.entries()) {
          newPoint = entry.points.find(p => p.timestamp.getTime() === point.timestamp.getTime() && p.imei === point.imei)

          if (newPoint !== null && typeof newPoint !== 'undefined') {
            break
          }
        }

        newPoint.lat = newPoint.latitude
        newPoint.lng = newPoint.longitude

        this.$refs.gmaps.showMarkerAndTooltip(newPoint)

        if (this.drawer) {
          this.$refs['chart' + point.imei].select(newPoint)
        }
      }
    },
    chartPointClickedEvent (point) {
      if (point !== null) {
        let newPoint = null

        for (const [index, entry] of this.planesPoints.entries()) {
          newPoint = entry.points.find(p => p.timestamp.getTime() === point.timestamp.getTime())

          if (newPoint !== null && typeof newPoint !== 'undefined') {
            break
          }
        }

        this.$refs.gmaps.clearMarkerAndTooltip()
        this.$refs.gmaps.showMarkerAndTooltip(newPoint)
        this.$refs.gmaps.moveTo(newPoint)
      }
    },

    eventClicked (event) {
      this.$refs.gmaps.clearMarkerAndTooltip()

      if (this.drawer) {
        this.$refs['chart' + this.plane.imei].clear()
      }

      let newPoint = null

      for (const [index, entry] of this.planesPoints.entries()) {
        newPoint = entry.points.find(p => p.timestamp.getTime() === event.timestamp.getTime())

        if (newPoint !== null && typeof newPoint !== 'undefined') {
          break
        }
      }

      newPoint.lat = newPoint.latitude
      newPoint.lng = newPoint.longitude

      this.$refs.gmaps.showMarkerAndTooltip(newPoint)
      this.$refs.gmaps.moveTo(newPoint)

      if (this.drawer) {
        this.$refs['chart' + this.plane.imei].select(newPoint)
      }
    },

    getFlightCoordinateBounds () {
      if (this.planesPoints.length === 0) {
        return
      }

      let minLat = this.planesPoints[0].points[0].latitude
      let minLng = this.planesPoints[0].points[0].longitude
      let maxLat = this.planesPoints[0].points[0].latitude
      let maxLng = this.planesPoints[0].points[0].longitude

      for (const [index, entry] of this.planesPoints.entries()) {
        entry.points.forEach(point => {
          if (minLat > point.latitude) {
            minLat = point.latitude
          }

          if (minLng > point.longitude) {
            minLng = point.longitude
          }

          if (maxLat < point.latitude) {
            maxLat = point.latitude
          }

          if (maxLng < point.longitude) {
            maxLng = point.longitude
          }
        })
      }

      return {
        minLat, minLng, maxLat, maxLng
      }
    },

    goToPreviousFlight () {
      const flightId = parseInt(this.$route.params.id)
      const flightIndex = this.filteredFlightsList.indexOf(flightId)
      const previousFlightId = this.filteredFlightsList[flightIndex - 1]

      this.$router.push(`/flights/${previousFlightId}`)
    },

    goToNextFlight () {
      const flightId = parseInt(this.$route.params.id)
      const flightIndex = this.filteredFlightsList.indexOf(flightId)
      const nextFlightId = this.filteredFlightsList[flightIndex + 1]

      this.$router.push(`/flights/${nextFlightId}`)
    },

    getEventNameByType (label) {
      switch (label) {
        case 'ignition_on':
          return 'Vžig motorja'
        case 'ignition_off':
          return 'Ugašanje motorja'
        case 'takeoff':
          return 'Vzlet'
        case 'landing':
          return 'Pristanek'
        case 'touchandgo':
          return 'Touch and go'
        case 'paratroopers':
          return 'Met padalcev'
        case 'aerotowing':
          return 'Aerovleka'
      }
    },

    getWarningNameByType (label) {
      switch (label) {
        case 'highg':
          return 'Velika G obremenitev'
        case 'lowg':
          return 'Majhna G obremenitev'
        case 'hardlanding':
          return 'Trdi pristanek'
        case 'highspeed':
          return 'Visoka hitrost'
        case 'lowflight':
          return 'Nizko letenje'
      }
    },

    getTimeByTimestamp (dateTime) {
      // const day = ('0' + dateTime.getDate()).slice(-2)
      // const month = ('0' + (dateTime.getMonth() + 1)).slice(-2)
      // const year = dateTime.getFullYear()

      const seconds = ('0' + dateTime.getSeconds()).slice(-2)
      const minutes = ('0' + dateTime.getMinutes()).slice(-2)
      const hours = ('0' + dateTime.getHours()).slice(-2)

      return `${hours}:${minutes}:${seconds}`
    },

    dateTimeToTime (dateTime) {
      const minutes = ('0' + dateTime.getMinutes()).slice(-2)
      const hours = ('0' + dateTime.getHours()).slice(-2)

      return `${hours}:${minutes}`
    },

    getFlightTime (startTime, endTime) {
      if (startTime === null || endTime === null) {
        return '00:00'
      }

      const timeDelta = endTime - startTime
      const hours = ('0' + Math.floor(timeDelta / 1000 / 60 / 60)).slice(-2)
      const minutes = ('0' + Math.round(timeDelta / 1000 / 60) % 60).slice(-2)

      return `${hours}:${minutes}`
    },

    getUTCDate () {
      const date = new Date()

      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    }
  },
  computed: {
    ...mapGetters('common', ['filteredFlightsList']),

    exportButtonDisabled () {
      return this.planesPoints.length === 0
    },
    planesPointsMaps () {
      const planesPoints = []

      for (const [index, entry] of this.planesPoints.entries()) {
        if (entry.points.length > 0) {
          const mappedPoints = entry.points.map(point => {
            return {
              lat: point.latitude,
              lng: point.longitude,
              ...point
            }
          })

          planesPoints.push({
            imei: entry.imei,
            color: (typeof this.plane !== 'undefined') ? this.plane.color : '#00FF00',
            points: mappedPoints
          })
        }
      }

      return planesPoints
    },
    drawerPosition () {
      let position = 'right'

      if (this.$vuetify.breakpoint.name === 'xs') {
        if (this.screenOrientation === 'landscape') {
          position = 'bottom'
        } else {
          position = 'right'
        }
      } else {
        if (this.layoutPosition === 'vertical') {
          position = 'bottom'
        } else {
          position = 'right'
        }
      }

      return position
    },

    previousFlightAvailable () {
      if (this.filteredFlightsList.length !== 0 && parseInt(this.filteredFlightsList[0]) !== parseInt(this.$route.params.id)) {
        return true
      } else {
        return false
      }
    },

    nextFlightAvailable () {
      if (this.filteredFlightsList.length !== 0 && parseInt(this.filteredFlightsList[this.filteredFlightsList.length - 1]) !== parseInt(this.$route.params.id)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.custom-map-buttons {
  box-shadow: 0 0 5px #CCC;
}

.custom-map-buttons-divider {
  height: 1px;
  background-color: #DDDDDD;
}

.flight-data > div {
  margin-left: 10px;
  margin-bottom: 10px;
}

.flight-data-dense > div {
  float: left;
  margin-left: 20px;
}
</style>
