<template>
  <v-container class="pl-5 pr-5" fluid>
    <v-row>
      <v-col class="mb-4">
        <div class="display-1 font-weight-medium pt-4">Dnevnik</div>
      </v-col>
      <v-col cols="auto" class="mt-4">
        <div v-if="user">
          <!-- <v-btn class="elevation-0" color="error" @click="$refs.removeFlightsDialog.show()" v-if="user.role > 1">
            <v-icon dark>
              mdi-delete
            </v-icon>
            Pobriši
          </v-btn> -->
          <v-btn class="elevation-0 ml-4" color="primary" @click="$refs.detectFlightsDialog.show()">
            Zaključi lete
          </v-btn>
          <v-btn class="elevation-0 ml-4" color="primary" @click="$refs.exportFlightsDialog.show()">
            Izpis
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <custom-table
          :headers="tableHeaders"
          :data="tableData"
          :data-length="dataLength"
          :activeRows="filteredFlightsVisitedList"
          rowId="id"
          @page-change="page => pageChanged(page)"
          @num-items-change="numItems => numItemsChanged(numItems)"
          @row-click="id => dataTableRowClicked(id)">
            <template slot="filters">
              <div :style="($vuetify.breakpoint.name === 'xs') ? 'width: 100%; overflow-x: scroll;' : ''">
                <v-row class="pl-4 pr-4" :style="($vuetify.breakpoint.name === 'xs') ? 'width: 200%;' : ''">
                  <v-col cols="3" class="pa-0 pl-4" v-if="!isEavio">
                    <v-select label="Uporabniki" v-model="selectedUser" :items="availableUsers" item-text="fullname" item-value="id" v-if="!selectedUser"></v-select>
                    <v-text-field label="Uporabniki" append-icon="mdi-close" :value="userById(selectedUser)" @click:append="selectedUser = null" readonly v-else></v-text-field>
                  </v-col>
                  <v-col cols="3" class="pa-0 ml-4">
                    <v-select label="Letala" v-model="selectedPlane" :items="availablePlanes" item-text="fullname" item-value="id" v-if="!selectedPlane"></v-select>
                    <v-text-field label="Letala" append-icon="mdi-close" :value="planeById(selectedPlane)" @click:append="selectedPlane = null" readonly v-else></v-text-field>
                  </v-col>
                  <v-col cols="3" class="pa-0 ml-4">
                    <v-select label="Nameni" v-model="selectedPurpose" :items="availablePurposes" item-text="label" item-value="id" v-if="!selectedPurpose"></v-select>
                    <v-text-field label="Nameni" append-icon="mdi-close" :value="purposeById(selectedPurpose)" @click:append="selectedPurpose = null" readonly v-else></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pa-0 ml-4">
                    <date-picker label="Datum" v-model="selectedDate" :allowed-dates="allowedDates" v-if="!selectedDate"></date-picker>
                    <v-text-field label="Datum" append-icon="mdi-close" :value="dateTimeToDate(selectedDate)" @click:append="selectedDate = null" readonly v-else></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template slot="customRows">
              <tr v-if="flights.length > 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Skupaj:</b></td>
                <td><b>{{ totalFlightTime }}</b></td>
                <td><b>{{ totalLandings }}</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </template>
            <!--<span slot="cell-status" slot-scope="row" style="color: ; font-weight: bold;" v-if="row.text.length > 0">
              {{ row.text }}
            </span>-->
            <!-- <v-btn slot="cell-warnings" slot-scope="row" icon color="error" v-if="row.text">
              <v-icon>mdi-alert</v-icon>
            </v-btn> -->
            <v-tooltip slot="cell-warnings" slot-scope="row" top v-if="row.text">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="error" v-bind="attrs" v-on="on">
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
              </template>
              <span>{{ row.text }}</span>
            </v-tooltip>
          </custom-table>
        </v-card>
      </v-col>
    </v-row>
    <detect-flights-dialog ref="detectFlightsDialog" v-if="!isEavio"></detect-flights-dialog>
    <export-flights-dialog ref="exportFlightsDialog" v-if="!isEavio"></export-flights-dialog>
    <remove-flights-dialog ref="removeFlightsDialog" v-if="!isEavio"></remove-flights-dialog>
    <view-flight-dialog ref="viewFlightDialog"></view-flight-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ViewFlightDialog from './ViewFlightDialog'
import ExportFlightsDialog from './ExportFlightsDialog'
import DetectFlightsDialog from './DetectFlightsDialog'
import RemoveFlightsDialog from './RemoveFlightsDialog'
import CustomTable from '@/components/CustomTable'
import DatePicker from '@/components/DatePicker'

export default {
  data: () => ({
    tableHeaders: [],
    tableData: [],
    dataLength: 0,
    users: [],
    availableUsers: [],
    planes: [],
    purposes: [],
    availablePlanes: [],
    availablePurposes: [],
    flightDates: [],
    isEavio: null, // To je za EAVIO redirect
    axiosOptions: null, // To je za EAVIO redirect
    selectedUser: null,
    selectedPlane: null,
    selectedPurpose: null,
    selectedDate: null,
    numItems: 25,
    currentPage: 1,
    flights: [],
    refreshInterval: 5000,
    refreshIntervalInstance: null,
    latestChange: null
  }),
  watch: {
    selectedUser (val) {
      this.ajax(true)
    },
    selectedPlane (val) {
      this.ajax(true)
    },
    selectedPurpose (val) {
      this.ajax(true)
    },
    selectedDate (val) {
      this.ajax(true)
    }
  },
  components: { DetectFlightsDialog, ExportFlightsDialog, RemoveFlightsDialog, ViewFlightDialog, CustomTable, DatePicker },
  mounted () {
    if (this.$route.name === 'EavioFlights') {
      this.isEavio = true

      this.axiosOptions = {
        headers: {
          eavio: this.$route.params.hash
        }
      }
    }

    this.init()
  },
  destroyed () {
    if (this.refreshIntervalInstance !== null && typeof this.refreshIntervalInstance !== 'undefined') {
      clearInterval(this.refreshIntervalInstance)
    }
  },
  methods: {
    ...mapActions('common', ['setFilteredFlightsList', 'resetFilteredFlightsVisitedList']),

    async init () {
      try {
        console.log(this.axiosOptions)

        const users = (await this.$axios.get('eavio_users', this.axiosOptions)).data.result
        this.users = users.map(user => {
          return {
            id: user.id,
            fullname: user.lastname + ' ' + user.firstname
          }
        })
      } catch (error) {
        console.log(error)
      }

      try {
        const planes = (await this.$axios.get('planes', this.axiosOptions)).data.result
        this.planes = planes.map(plane => {
          return {
            id: plane.id,
            fullname: plane.name + ' / ' + plane.registration
          }
        })
      } catch (error) {
        console.log(error)
      }

      try {
        const purposes = (await this.$axios.get('purposes', this.axiosOptions)).data.result
        this.purposes = purposes.map(purpose => {
          return {
            id: purpose.id,
            label: `${purpose.label} (${purpose.section_id})`
          }
        })
      } catch (error) {
        console.log(error)
      }

      this.tableHeaders = [
        { label: 'Datum', style: 'width: 7%; max-width: 7%;' },
        { label: 'Letalo', style: 'min-width: 270px;' },
        { label: 'Pilot', style: 'width: 13%; max-width: 13%;' },
        { label: 'Inštruktor', style: 'width: 5%; max-width: 5%;' },
        { label: 'Kraj vzleta - pristanka', style: 'width: 8%; max-width: 8%;' },
        { label: 'Čas vzleta - pristanka', style: 'width: 10%; max-width: 10%;' },
        { label: 'Čas letenja', style: 'width: 5%; max-width: 5%;' },
        { label: 'Št. prist.', style: 'width: 2%; max-width: 2%;' },
        { label: 'Namen', style: 'width: 9%; max-width: 9%;' },
        { label: 'Opombe', style: 'width: 9%; max-width: 9%;' },
        { label: 'Opozorila', style: 'width: 5%; max-width: 5%;' },
        { label: 'Status', style: 'width: 10%; max-width: 10%;' }
      ]

      this.refreshIntervalInstance = setInterval(() => {
        this.ajax()
      }, this.refreshInterval)

      this.ajax(true)
    },

    ajax (change) {
      const payload = {
        timestamp_from: 1609369200,
        timestamp_to: (Math.round((new Date()).getTime() / 1000)),
        page: this.currentPage,
        pagination: this.numItems
      }

      if (this.selectedUser) {
        payload.user_id = this.selectedUser
      }

      if (this.selectedPlane) {
        payload.plane_id = this.selectedPlane
      }

      if (this.selectedPurpose) {
        payload.purpose_id = this.selectedPurpose
      }

      if (this.selectedDate) {
        const selectedDateFrom = new Date((new Date(this.selectedDate)).setHours(0, 0, 0, 0))
        const selectedDateTo = new Date((new Date(this.selectedDate)).setHours(0, 0, 0, 0))

        payload.timestamp_from = Math.round(selectedDateFrom.getTime() / 1000)
        selectedDateTo.setHours(23, 59, 0, 0)
        payload.timestamp_to = Math.round(selectedDateTo.getTime() / 1000)
      }

      if (this.latestChange && !change) {
        payload.latest_change = this.latestChange
      }

      // this.resetFilteredFlightsVisitedList()

      this.$axios.post('flights', payload, this.axiosOptions)
        .then(response => {
          const data = response.data.result

          if (!('flights' in data)) {
            return
          }

          this.dataLength = data.total
          this.flights = [...data.flights]
          this.latestChange = data.latest_change
          this.setFilteredFlightsList(this.flights.map(flight => {
            return flight.eavio_flight_id
          }))

          this.availableUsers = []
          this.availablePlanes = []
          this.availablePurposes = []
          this.flightDates = []

          data.filter_data.pilot_ids.forEach(userId => {
            const user = this.users.find(user => user.id === userId)

            if (user) {
              this.availableUsers.push({ ...user })
            }
          })

          // this.availableUsers.sort((a, b) => (a.fullname > b.fullname ? 1 : -1))
          this.availableUsers.sort((a, b) => a.fullname.localeCompare(b.fullname, 'sl'))

          data.filter_data.plane_ids.forEach(planeId => {
            const plane = this.planes.find(plane => plane.id === planeId)

            if (plane) {
              this.availablePlanes.push({ ...plane })
            }
          })

          this.availablePlanes.sort((a, b) => (a.fullname > b.fullname ? 1 : -1))

          data.filter_data.purpose_ids.forEach(purposeId => {
            const purpose = this.purposes.find(purpose => purpose.id === purposeId)

            if (purpose) {
              this.availablePurposes.push({ ...purpose })
            }
          })

          this.availablePurposes.sort((a, b) => a.label.localeCompare(b.label, 'sl'))

          data.filter_data.available_dates.forEach(date => {
            this.flightDates.push(date)
          })

          this.tableData = data.flights.map(row => {
            const takeoffTime = this.dateTimeToTime(new Date(row.start_timestamp))
            const landingTime = this.dateTimeToTime(new Date(row.end_timestamp))
            const timeChanged = (row.eavio_changes.includes('start_timestamp') || row.eavio_changes.includes('end_timestamp'))
            const textColor = (row.modified) ? 'red' : 'black'
            // const textColor = 'black'

            return [
              { value: row.eavio_flight_id, field: 'id', style: 'display: none;' }, // More bit zaradi onClick eventa - da veš kaj si kliknil.
              { value: this.dateTimeToDate(new Date(row.start_timestamp)), field: 'flightDate', style: `color: ${textColor};` },
              { value: row.plane, field: 'plane', style: `color: ${textColor};` },
              { value: row.pilot, field: 'pilot', style: `color: ${textColor};` },
              { value: row.instructor, field: 'instructor', style: `color: ${textColor};` },
              { value: `${row.takeoff_airport.mark} - ${row.landing_airport.mark}`, field: 'takeoffLandingAirport', style: `color: ${textColor};` },
              { value: `${takeoffTime} - ${landingTime}`, field: 'startEndTimestamp', style: 'color: ' + (timeChanged ? 'red' : textColor) + ';' },
              { value: this.getFlightTime(new Date(row.start_timestamp), new Date(row.end_timestamp)), field: 'flightTime', style: 'color: ' + (timeChanged ? 'red' : textColor) + ';' },
              { value: row.num_landings, field: 'landings', style: `color: ${textColor};` },
              { value: row.purpose, field: 'purpose', style: 'color: ' + ((row.eavio_changes.includes('purpose')) ? 'red' : textColor) + ';' },
              { value: row.comment, field: 'comment', style: `color: ${textColor};` },
              { value: row.warnings, field: 'warnings', style: `text-align: center; color: ${textColor};` },
              { value: row.status, field: 'status', style: `font-weight: bold; color: ${this.getStatusColor(row.status)}` }
            ]
          })
        })
        .catch(error => {
          console.log(error)
          // this.tableData = []
        })
    },

    allowedDates (value) {
      return this.flightDates.includes(value)
    },

    userById (id) {
      const user = this.users.find(user => user.id === id)

      return user ? user.fullname : null
    },

    planeById (id) {
      const plane = this.planes.find(plane => plane.id === id)

      return plane ? plane.fullname : null
    },

    purposeById (id) {
      const purpose = this.purposes.find(purpose => purpose.id === id)

      return purpose ? purpose.label : null
    },

    dataTableRowClicked (id) {
      const flight = this.flights.find(flight => flight.eavio_flight_id === id)
      this.$refs.viewFlightDialog.show(flight)
    },

    numItemsChanged (numItems) {
      this.numItems = numItems
      this.ajax(true)
    },

    pageChanged (page) {
      this.currentPage = page
      this.ajax(true)
    },

    dateTimeToDate (dateTime) {
      const day = ('0' + dateTime.getDate()).slice(-2)
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2)
      const year = dateTime.getFullYear()

      return `${day}.${month}.${year}`
    },

    dateTimeToTime (dateTime) {
      const minutes = ('0' + dateTime.getMinutes()).slice(-2)
      const hours = ('0' + dateTime.getHours()).slice(-2)

      return `${hours}:${minutes}`
    },

    getFlightTime (startTime, endTime) {
      const timeDelta = endTime - startTime
      const hours = ('0' + Math.floor(timeDelta / 1000 / 60 / 60)).slice(-2)
      const minutes = ('0' + Math.round(timeDelta / 1000 / 60) % 60).slice(-2)

      return `${hours}:${minutes}`
    },

    getStatusColor (status) {
      switch (status) {
        case 'Nepotrjen':
          return '#1976d2'
        case 'Potrjen':
          return '#4CAF50'
        case 'Reklamacija':
          return '#F44336'
        case 'Preklican':
          return '#F44336'
      }

      return '#1976d2'
    }
  },
  computed: {
    ...mapGetters('common', ['user', 'filteredFlightsObjectList', 'filteredFlightsVisitedList']),

    totalFlightTime () {
      let totalFlightTimeDelta = 0

      this.flights.forEach(flight => {
        const timeDelta = new Date(flight.end_timestamp) - new Date(flight.start_timestamp)
        totalFlightTimeDelta += timeDelta
      })

      const hours = ('0' + Math.floor(totalFlightTimeDelta / 1000 / 60 / 60)).slice(-2)
      const minutes = ('0' + Math.round(totalFlightTimeDelta / 1000 / 60) % 60).slice(-2)

      return `${hours}:${minutes}`
    },

    totalLandings () {
      let totalLandings = 0

      this.flights.forEach(flight => {
        totalLandings += flight.num_landings
      })

      return totalLandings
    }
  }
}
</script>
<style scoped>

</style>
