<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Dodaj letalo</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Tip letala" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Registracija"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Napetost" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Max. G" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Min. hitrost" type="number" suffix="km/h"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Max. hitrost" type="number" suffix="km/h"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox label="Block time"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox v-model="flightTime" label="Flight time"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox label="Met padalcev"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox label="Aerovleka"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox label="Motorno letalo"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox v-model="glider" label="Jadralno letalo"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pb-0">
              <v-btn color="primary" text @click="dialog = false">
                Zapri
              </v-btn>
              <v-btn class="elevation-0" color="primary" @click="dialog = false">
                Dodaj
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    flightTime: true,
    glider: true
  }),
  mounted () {
  },
  methods: {
    show () {
      this.dialog = true
    }
  },
  computed: {
  }
}
</script>
<style scoped>
</style>
