<template>
  <div class="drawer">
    <slot></slot>
  </div>
</template>

<script>

export default {
  data: () => ({
    width: 500,
    minSize: 3
  }),
  mounted () {
    this.setEvents()
  },
  methods: {
    setEvents () {
      /* const minSize = this.minSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')

      drawerBorder.style.width = this.minSize + 'px'
      drawerBorder.style.cursor = 'ew-resize'
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize (e) {
        document.body.style.cursor = 'ew-resize'
        const f = direction === 'right'
          ? document.body.scrollWidth - e.clientX
          : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener('mousedown', (e) => {
        if (e.offsetX < minSize) {
          el.style.transition = 'initial'
          document.addEventListener('mousemove', resize, false)
        }
      }, false)

      document.addEventListener('mouseup', () => {
        el.style.transition = ''
        this.width = el.style.width
        document.body.style.cursor = ''
        document.removeEventListener('mousemove', resize, false)
        this.$emit('resized', this.width)
        console.log(this.width)
      }, false)
      */
    }
  }
}
</script>

<style scoped>
.drawer {
  background-color: white;
  overflow-y: scroll;
}
</style>
