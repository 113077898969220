<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Izvoz dnevnika</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="6" class="pt-0 pb-1">
                  <custom-select class="custom-select-height-250" label="Uporabniki" v-model="selectedUsers" :items="users" item-text="label" item-value="value" prepend-icon="mdi-account"></custom-select>
                </v-col>
                <v-col cols="6" class="pt-0 pb-1">
                  <custom-select class="custom-select-height-250" label="Letala" v-model="selectedPlanes" :items="planes" item-text="label" item-value="value" prepend-icon="mdi-airplane"></custom-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0 pb-1">
                  <custom-select class="custom-select-height-250" label="Nameni" v-model="selectedPurposes" :items="purposes" item-text="label" item-value="value" prepend-icon="mdi-airplane"></custom-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0 pb-1">
                  <date-picker label="Od" :value="selectedDateFrom" @input="date => selectedDateFrom = date"></date-picker>
                </v-col>
                <v-col cols="6" class="pt-0 pb-1">
                  <date-picker label="Do" :value="selectedDateTo" @input="date => selectedDateTo = date"></date-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-radio-group v-model="selectedExportOption" row>
                    <v-radio label="PDF" value="pdf"></v-radio>
                    <v-radio label="CSV" value="csv"></v-radio>
                    <v-radio label="Excel" value="xlsx"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-5">
                  <v-checkbox v-model="showComment" label="Prikaži komentar"></v-checkbox>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-5">
                  <v-checkbox v-model="exportDetails" label="Izvoz podrobnosti"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pb-0">
              <v-btn color="primary" text @click="dialog = false">
                Zapri
              </v-btn>
              <v-btn class="elevation-0 ml-2" color="primary" @click="exportData" :loading="exporting" :disabled="selectedUsers.length === 0 || selectedPlanes.length === 0 || selectedPurposes.length === 0 || selectedDateFrom === null || selectedDateTo === null">
                Izvozi
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomSelect from '@/components/CustomSelect'
import DatePicker from '@/components/DatePicker'

export default {
  data: () => ({
    dialog: false,
    planes: [],
    users: [],
    purposes: [],
    exporting: false,
    selectedPlanes: [],
    selectedUsers: [],
    selectedPurposes: [],
    selectedDateFrom: null,
    selectedDateTo: null,
    selectedExportOption: 'pdf',
    exportDetails: true,
    showComment: false,
    canExport: true
  }),
  components: { CustomSelect, DatePicker },
  watch: {
    selectedDateFrom (val) {
      if (this.selectedDateFrom > this.selectedDateTo) {
        this.selectedDateTo = new Date(this.selectedDateFrom)
      }
    },
    selectedDateTo (val) {
      if (this.selectedDateFrom > this.selectedDateTo) {
        this.selectedDateFrom = new Date(this.selectedDateTo)
      }
    }
  },
  mounted () {
    this.$axios.get('planes')
      .then(response => {
        const data = response.data.result

        this.planes = data.map(plane => {
          return {
            value: plane.id,
            label: plane.name + ' / ' + plane.registration
          }
        })
      })
      .catch(error => {
        console.log(error)
      })

    this.$axios.get('eavio_users')
      .then(response => {
        const data = response.data.result

        this.users = data.map(user => {
          return {
            value: user.id,
            label: user.lastname + ' ' + user.firstname
          }
        })

        this.users.sort((a, b) => a.label.localeCompare(b.label, 'sl'))
      })
      .catch(error => {
        console.log(error)
      })

    this.$axios.get('purposes')
      .then(response => {
        const data = response.data.result

        this.purposes = data.map(purpose => {
          return {
            value: purpose.id,
            label: `${purpose.label} (${purpose.section_id})`
          }
        })

        this.purposes.sort((a, b) => a.label.localeCompare(b.label, 'sl'))
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    show () {
      this.dialog = true
      this.canExport = true
      this.selectedUsers = []
      this.selectedPlanes = this.planes.map(plane => plane.value)
      this.selectedPurposes = this.purposes.map(purpose => purpose.value)
      this.selectedDateFrom = new Date((new Date().setHours(0, 0, 0, 0)))
      this.selectedDateTo = new Date()
    },

    exportData () {
      this.exporting = true

      const payload = {
        file_type: this.selectedExportOption,
        timestamp_from: Math.round(this.selectedDateFrom.getTime() / 1000),
        timestamp_to: Math.round(this.selectedDateTo.getTime() / 1000),
        users: this.selectedUsers,
        planes: this.selectedPlanes,
        purposes: this.selectedPurposes,
        details: this.exportDetails,
        comment: this.showComment
      }

      this.$axios.post('flights/export', payload, { responseType: 'blob' })
        .then(response => {
          const contentDisposition = response.headers['content-disposition']
          const fileName = contentDisposition.split('attachment; filename=')[1]
          this.$fileDownload(response.data, fileName)
          this.canExport = false
          this.exporting = false

          setTimeout(() => {
            this.canExport = true
          }, 3000)
        })
        .catch(error => {
          this.exporting = false
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
</style>
