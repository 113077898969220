<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Dodaj uporabnika</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Ime *" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Priimek"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Geslo *" type="password" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Ponovno *" type="password" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select label="Vloga" v-model="role" :items="roles"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pb-0">
              <v-btn color="primary" text @click="dialog = false">
                Zapri
              </v-btn>
              <v-btn class="elevation-0" color="primary" @click="dialog = false">
                Dodaj
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    roles: ['Uporabnik', 'Moderator', 'Administrator'],
    role: 'Uporabnik'
  }),
  mounted () {
  },
  methods: {
    show () {
      this.dialog = true
    }
  },
  computed: {
  }
}
</script>
<style scoped>
</style>
